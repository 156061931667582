import { combineReducers } from 'redux';
import appConfigurations from './appConfigurations';
import myObjects from './myObjects';
import redirect from './redirect';

import {
  registration,
  login,
  confirmRegistration,
  sharedSingleRealEstateObject,
} from '../allAccess/reducers';

import {
  user,
  organization,
  client,
  clientList,
  myObjectsList,
  objectsList,
  clientFilter,
  tag,
  realEstateObject,
} from 'realtor/reducers';

const allReducers = combineReducers({
  login,
  appConfigurations,
  myObjects,
  registration,
  confirmRegistration,
  user,
  organization,
  client,
  clientList,
  myObjectsList,
  objectsList,
  redirect,
  clientFilter,
  tag,
  realEstateObject,
  sharedSingleRealEstateObject,
});

export default allReducers;
