import React from 'react';
import { IconButton, Toolbar } from '@material-ui/core';
import clsx from 'clsx';
import MenuIcon from '@material-ui/icons/Menu';
import i18n from '../../../../common/i18n';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { AccountCircle } from '@material-ui/icons';

const Header = ({
  classes,
  onDrawerToggle,
  isMenuOpen,
  onShareLinkDialogOpen,
  onProfileMenuOpen,
  accountMenuId,
} ) => {
  return (
    <Toolbar className={classes.header}>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={onDrawerToggle}
        className={clsx(classes.menuButton, {
          [classes.hide]: isMenuOpen,
        })}
      >
        <MenuIcon />
      </IconButton>
      <Button
        component={Link}
        to={'/objects'}
        variant="contained"
        color="default"
        startIcon={<ArrowBackIcon />}
      >{i18n.t('MY_OBJECTS')}
      </Button>
      <Button
        className={classes.shareObjectButton}
        onClick={onShareLinkDialogOpen}
        variant="contained"
        color="primary"
      >{i18n.t('SHARE_PROFILE_BUTTON')}
      </Button>
      <div className={classes.rightBar}>
        <div>
          <IconButton
            edge="end"
            aria-label="account of current user"
            aria-haspopup="true"
            color="inherit"
            aria-controls={accountMenuId}
            onClick={onProfileMenuOpen}
          >
            <AccountCircle />
          </IconButton>
        </div>
      </div>
    </Toolbar>
  );
};

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  onDrawerToggle: PropTypes.func.isRequired,
  onShareLinkDialogOpen: PropTypes.func.isRequired,
  onProfileMenuOpen: PropTypes.func.isRequired,
  isMenuOpen: PropTypes.bool.isRequired,
  accountMenuId: PropTypes.string.isRequired,
};

export default Header;

