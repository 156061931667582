import { connect } from 'react-redux';

import {
  fetchAllClients,
  deleteAndFetchList,
  deleteClientFilterAndFetchList,
  fetchTagsList, fetchAllTotalAndList, removeFromFavorite,
} from '../actions';
import SearchClientPage from '../components/pages/SearchClientPage/SearchClientPage';
import { addToFavorite } from 'realtor/actions/favoriteClients';

const mapStateToProps = (state) => {
  const { appConfigurations, clientList, tag } = state;

  return {
    paramsList: appConfigurations.objectFilters,
    clientList: clientList.list,
    totalListCount: clientList.totalListCount,
    totalCount: clientList.totalCount,
    inProgress: clientList.inProgress,
    listInProgress: clientList.listInProgress,
    citiesList: appConfigurations.citiesList,
    tagsList: tag.tagsList,
  };
};

const mapDispatchToProps = {
  fetchAllClients,
  fetchAllTotalAndList,
  onDelete: deleteAndFetchList,
  onDeleteClientFilter: deleteClientFilterAndFetchList,
  fetchTagsList,
  addToFavorite,
  removeFromFavorite,
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchClientPage);
