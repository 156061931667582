import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import AppContainer from './AppConteiner';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { theme } from '../../common/theme';
import { getAppConfiguration } from '../actions/appConfiguration';
import PropTypes from 'prop-types';
import { CircularProgress } from '@material-ui/core';
import ReactSAlert from '../../common/components/ReactSAlert/ReactSAlert';

const mapStateToProps = ({ appConfigurations }) => {
  return {
    ready: !isEmpty(appConfigurations.errorCodes),
    inProgress: appConfigurations.inProgress,
    isMobile: appConfigurations.isMobile,
  };
};

const loaderBoxStyle = {
  overflow: 'hidden',
  display: 'flex',
  height: '100vh',
  alignItems: 'center',
  justifyContent: 'center',
};

const mapDispatchToProps = {
  getAppConfiguration,
};

export class AppLoaderContainer extends PureComponent {

  componentDidMount() {
    this.props.getAppConfiguration();
  }

  renderAppLoader() {
    return (
      <div style={loaderBoxStyle}>
        <CircularProgress />
      </div>
    );
  }

  renderContent = () => (<AppContainer/>)

  render() {
    const { ready, inProgress, isMobile } = this.props;
    const showContent = ready && !inProgress;

    theme.isMobile = isMobile;

    return (
      <MuiThemeProvider theme={theme}>
        <ReactSAlert />
        {showContent ? this.renderContent() : inProgress ? this.renderAppLoader() : null}
      </MuiThemeProvider>
    );
  }
}

AppLoaderContainer.propTypes = {
  ready: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
  inProgress: PropTypes.bool.isRequired,
  getAppConfiguration: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(AppLoaderContainer);
