import ObjectResource from '../resourses/Object.resource';
import { Client, TelephoneNumberBuilder } from 'realtor/domain';

export const FETCH_MY_OBJECTS_START = 'FETCH_MY_OBJECTS_START';
export const FETCH_MY_OBJECTS_FAIL = 'FETCH_MY_OBJECTS_FAIL';
export const FETCH_MY_OBJECTS_SUCCESS = 'FETCH_MY_OBJECTS_SUCCESS';

export const FETCH_MY_OBJECTS_TOTAL_START = 'FETCH_MY_OBJECTS_TOTAL_START';
export const FETCH_MY_OBJECTS_TOTAL_FAIL = 'FETCH_MY_OBJECTS_TOTAL_FAIL';
export const FETCH_MY_OBJECTS_TOTAL_SUCCESS = 'FETCH_MY_OBJECTS_TOTAL_SUCCESS';

export const DELETE_OBJECT_START = 'DELETE_OBJECT_START';
export const DELETE_OBJECT_SUCCESS = 'DELETE_OBJECT_SUCCESS';
export const DELETE_OBJECT_FAIL = 'DELETE_OBJECT_FAIL';

const deleteObjectStart = () => {
  return { type: DELETE_OBJECT_START };
};

const deleteObjectSuccess = () => {
  return { type: DELETE_OBJECT_SUCCESS };
};

const deleteObjectFail = (error) => {
  return {
    type: DELETE_OBJECT_FAIL,
    error,
  };
};

const fetchMyObjectsTotalStart = () => {
  return { type: FETCH_MY_OBJECTS_TOTAL_START };
};

const fetchMyObjectsTotalFail = (error) => {
  return { type: FETCH_MY_OBJECTS_TOTAL_FAIL, error };
};

const fetchMyObjectsTotalSuccess = (data) => {
  return { type: FETCH_MY_OBJECTS_TOTAL_SUCCESS, data };
};

const fetchMyObjectsStart = () => {
  return { type: FETCH_MY_OBJECTS_START };
};

const fetchMyObjectsFail = (error) => {
  return { type: FETCH_MY_OBJECTS_FAIL, error };
};

const fetchMyObjectsSuccess = (data) => {
  return { type: FETCH_MY_OBJECTS_SUCCESS, data: {
    ...data,
    result: data.result.map((realEstateObject) => {

      const client = realEstateObject.client ?
        new Client({
          ...realEstateObject.client,
          telephoneNumber: TelephoneNumberBuilder.buildFromServerData(realEstateObject.client.telephoneNumber),
        }) :
        null;
      return {
        ...realEstateObject,
        client,
      };
    }),
  } };
};

export const fetchMyObjects = (params) => {
  return (dispatch) => {
    dispatch(fetchMyObjectsStart());
    ObjectResource.getObjectsList(params)
      .then((response) => {
        return dispatch(fetchMyObjectsSuccess(response));
      })
      .catch((error) => {
        return dispatch(fetchMyObjectsFail(error));
      });
  };
};

export const fetchMyObjectsTotal = (params) => {
  return (dispatch, getState) => {
    dispatch(fetchMyObjectsTotalStart());
    dispatch(fetchMyObjectsStart());
    ObjectResource.getObjectsTotal()
      .then((data) => {
        dispatch(fetchMyObjectsTotalSuccess(data));
        if (data.total) fetchMyObjects(params)(dispatch, getState);
      })
      .catch((error) => {
        return dispatch(fetchMyObjectsTotalFail(error));
      });
  };
};

export const deleteObject = (realEstateObject, params) => {
  return (dispatch) => {
    dispatch(deleteObjectStart());
    ObjectResource.deleteObject(realEstateObject.id)
      .then(() => {
        dispatch(deleteObjectSuccess());
        dispatch(fetchMyObjects(params));
      })
      .catch((err) => {
        dispatch(deleteObjectFail(err));
      });
  };
};
