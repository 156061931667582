import i18n from '../../../../../../../common/i18n';
import { Button } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import React from 'react';
import PropTypes from 'prop-types';
import RealtorPageHeader from 'realtor/components/layears/RealtorPageHeader/RealtorPageHeader';

const ObjectsPageHeader = ({ classes, totalCount, title, isCreateButtonDisabled }) => {
  return (
    <RealtorPageHeader>
      <div className={classes.headerBox}>
        <div>
          <h1>{title}</h1>
          <span>{`${totalCount} ${i18n.t('OBJECTS_COUNT')}`}</span>
        </div>
        {!isCreateButtonDisabled &&
          <Button
            component={RouterLink}
            to={'/real-estate-object/create'}
            className={classes.addObjectButton}
            variant="contained"
          >
            {i18n.t('ADD_OBJECT')}
          </Button>
        }
      </div>
    </RealtorPageHeader>
  );
};

ObjectsPageHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  totalCount: PropTypes.number,
  title: PropTypes.string,
  isCreateButtonDisabled: PropTypes.bool,
};

export default ObjectsPageHeader;
