import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import i18n from '../../../../../common/i18n';
import SelectButtonByFilter from '../../common/SelectButtonByFilter/SelectButtonByFilter';
import { FormValidation } from '../../../../../common/helpers/FormValidation';
import ObjectFiltersByType from './ObjectFiltersByType';
import { getDefaultFilterByType } from 'realtor/helpers/clientFilterParams';
import FiltersDrawer from 'realtor/components/common/FitlersDrawer/FiltersDrawer';
import { realEstateObjectFilterValidationFactory } from 'realtor/validation/realEstateObjectFilter';

const DEFAULT_FILTER = {
  currency: 1,
};

class ObjectFiltersBar extends PureComponent {
  state = {
    filter: DEFAULT_FILTER,
    filterErrors: {},
  }

  handleReset = () => {
    const { onReset } = this.props;

    this.setState({
      ...this.state,
      filter: DEFAULT_FILTER,
      filterErrors: {},
    });

    onReset();
  }

  handleSelectButtonChange = (name, value) => {
    const valueForSet = value !== this.state.filter[name] ? value : null;

    const filter = {
      ...this.state.filter,
      [name]: valueForSet,
    };

    return this.setState({
      ...this.state,
      filter,
    });
  }

  handleSelectButtonMultipleChange = (name, value, isAllSelected) => {
    let param = new Set(this.state.filter[name]);

    if (!param || isAllSelected) {
      param = new Set();
    }

    param.has(value) ? param.delete(value) : param.add(value);

    return this.setState({
      ...this.state,
      filter: {
        ...this.state.filter,
        [name]: param,
      },
    });
  }

  handleRangeFilterChange = (name, params) => {
    return this.setState({
      ...this.state,
      filter: {
        ...this.state.filter,
        [name]: params,
      },
    });
  }

  handleFilterRealEstateTypeSelect = (name, value) => {
    return this.setState({
      ...this.state,
      filterErrors: {},
      filter: {
        ...getDefaultFilterByType(value, this.props.filtersList),
        ...DEFAULT_FILTER,
        [name]: value,
      },
    });
  }

  handleFilterChange = (name, value) => {
    // WE NEED THIS BECAUSE WE DON'T SEND THE FALSE VALUE TO THE FILTER!
    if ((name === 'isMortgage' || name === 'isLastFloor' || name === 'isFirstFloor') && value === false) {
      const filterCopy = { ...this.state.filter };

      if (filterCopy[name] !== undefined) delete filterCopy[name];

      return this.setState({
        ...this.state,
        filter: filterCopy,
      });
    }

    const filter = {
      ...this.state.filter,
      [name]: value,
    };

    return this.setState({
      ...this.state,
      filter,
    });
  }

  handleInputChange = (event) => {
    const { name, value } = event.target;
    this.handleFilterChange(name, value);
  }

  validateFilterForm = () => {
    const { exchangeRates, paramsList } = this.props;

    const validator = realEstateObjectFilterValidationFactory(
      paramsList.objectType,
      exchangeRates,
      this.state.filter
    );

    const errors = validator.validate();

    this.setState({
      ...this.state,
      filterErrors: errors,
    });

    return {
      isFilterFormValid: FormValidation.isFormValid(errors),
      realEstateObjectErrors: errors,
    };
  }

  handleConfirm = () => {
    const { onConfirm } = this.props;
    const { filter } = this.state;

    const { isFilterFormValid } = this.validateFilterForm();

    return isFilterFormValid && onConfirm(filter);
  }

  handleCityChange = (event) => {
    const cityId = get(event, 'cityId', null);
    const districtId = cityId ? this.state.filter.districtId : null;

    this.setState({
      ...this.state,
      filter: {
        ...this.state.filter,
        cityId,
        districtId,
      },
    });
  }

  handleDistrictChange = (data) => {
    this.setState({
      ...this.state,
      filter: {
        ...this.state.filter,
        districtId: data ? data.id : null,
      },
    });
  }

  renderFiltersListByType() {
    const { filtersList, citiesList } = this.props;
    const { filter, filterErrors } = this.state;

    return (
      <ObjectFiltersByType
        filtersList={filtersList}
        filter={filter}
        onDistrictChange={this.handleDistrictChange}
        onCityChange={this.handleCityChange}
        onFilterChange={this.handleFilterChange}
        onSelectButtonChange={this.handleSelectButtonChange}
        onSelectButtonMultipleChange={this.handleSelectButtonMultipleChange}
        onMultiSelectAll={this.handleRangeFilterChange}
        onInputChange={this.handleInputChange}
        filterErrors={filterErrors}
        citiesList={citiesList}
      />
    );
  }

  renderTypeFilter() {
    const { filtersList } = this.props;
    const { filter } = this.state;

    const filterName = 'objectType';
    const buttonTitlePrefix = 'FILTER_REAL_ESTATE_TYPE_';
    const header = `${i18n.t('PARAMS_FILTER_TYPE')}:`;
    return (
      <SelectButtonByFilter
        filter={filter}
        filtersList={filtersList}
        filterName={filterName}
        buttonTitlePrefix={buttonTitlePrefix}
        header={header}
        onSelect={this.handleFilterRealEstateTypeSelect}
      />
    );
  }
  render() {
    const {
      onFiltersToggle,
      isFiltersOpen,
    } = this.props;

    const typeFilter = this.renderTypeFilter();
    const filtersListByTypeComponent = this.renderFiltersListByType();

    return (
      <FiltersDrawer
        isFiltersOpen={isFiltersOpen}
        onFiltersToggle={onFiltersToggle}
        typeFilterComponent={typeFilter}
        filtersListByTypeComponent={filtersListByTypeComponent}
        onConfirm={this.handleConfirm}
        onReset={this.handleReset}
      />
    );
  }
}

export default ObjectFiltersBar;

ObjectFiltersBar.propTypes = {
  onFiltersToggle: PropTypes.func.isRequired,
  isFiltersOpen: PropTypes.bool,
  filtersList: PropTypes.object,
  paramsList: PropTypes.object,
  exchangeRates: PropTypes.object,
  citiesList: PropTypes.array,
  onConfirm: PropTypes.func,
  onReset: PropTypes.func,
};
