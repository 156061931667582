import React, { PureComponent } from 'react';
import PropTypes, { bool } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  BrowserRouter as Router,
} from 'react-router-dom';
import 'react-s-alert/dist/s-alert-default.css';

import styles from './styles';
import Cabinet from '../../containers/Cabinet';
import AllAccessRoutes from '../../allAccess/routes/AllAccessRoutes';


export class App extends PureComponent {
  renderNotAuthorizedRoutes() {
    const { isConfirmationTokenExist } = this.props;

    return (
      <AllAccessRoutes isConfirmationTokenExist={isConfirmationTokenExist} />
    );
  }

  renderAuthorizedRoutes() {
    const { isAppInitialized, redirectPath } = this.props;

    return (
      <Cabinet isAppInitialized={isAppInitialized} redirectPath={redirectPath}></Cabinet>
    );
  }

  render() {
    const {
      isAuthorized,
      classes,
    } = this.props;

    return (
      <div className={classes.appWrapper}>
        <main className={classes.mainContent}>
          <Router>
            {isAuthorized ? this.renderAuthorizedRoutes() : this.renderNotAuthorizedRoutes() }
          </Router>
        </main>
      </div>
    );
  };
};

export default withStyles(styles)(App);

App.propTypes = {
  isAuthorized: bool,
  isConfirmationTokenExist: bool,
  isAppInitialized: bool,
  classes: PropTypes.object.isRequired,
  redirectPath: PropTypes.string,
};
