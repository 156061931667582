import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';

import {
  RoomsCountFilter,
  CurrencyFilter,
  TotalPriceFilter,
  AreaFilter,
  CitiesFilter,
  PartialServiceTypeFilter,
  ServiceTypeFilter,
  MaterialFilter,
  KitchenAreaFilter,
  LivingAreaFilter,
  LandAreaFilter,
  ConditionFilter,
  FlooringFilter,
  MortgageFilter,
  FirstFloorFilter,
  LastFloorFilter,
  SquarePriceFilter,
  SubtypeFilter,
  FloorFilter,
  LandAreaPriceFilter,
} from '../Filters';
import i18n from '../../../../../common/i18n';

const MAX_NUMBER_VALUE = 9999999999;

export class FiltersByType extends PureComponent {
  handleNumberParamsChange = ({ target }) => {
    const { onInputChange } = this.props;
    const valueAsNumber = Number(target.value);
    const data = {
      name: target.name,
      value: valueAsNumber > 0 ? (Number(valueAsNumber) > MAX_NUMBER_VALUE ? MAX_NUMBER_VALUE : target.value) : null,
    };

    onInputChange({ target: data });
  }

  renderServiceTypeFilter() {
    const { filtersList, filter, onSelectButtonChange } = this.props;
    return <ServiceTypeFilter filtersList={filtersList} filter={filter} onFilterChange={onSelectButtonChange} />;
  }

  renderSubtypeFilter() {
    const { filtersList, filter, onSelectButtonChange } = this.props;
    return <SubtypeFilter filtersList={filtersList} filter={filter} onFilterChange={onSelectButtonChange} />;
  }

  renderMortgageFilter() {
    const { filter, onFilterChange } = this.props;
    return <MortgageFilter filter={filter} onFilterChange={onFilterChange} />;
  }

  renderFirstFloorFilter() {
    const { filter, onFilterChange } = this.props;
    return <FirstFloorFilter filter={filter} onFilterChange={onFilterChange} />;
  }

  renderLastFloorFilter() {
    const { filter, onFilterChange } = this.props;
    return <LastFloorFilter filter={filter} onFilterChange={onFilterChange} />;
  }

  renderPartialServiceTypeFilter() {
    const { filtersList, filter, onSelectButtonChange } = this.props;
    return <PartialServiceTypeFilter filtersList={filtersList} filter={filter} onFilterChange={onSelectButtonChange} />;
  }

  renderRoomsCount() {
    const { filtersList, filter, onSelectButtonChange } = this.props;
    return <RoomsCountFilter filtersList={filtersList} filter={filter} onFilterChange={onSelectButtonChange} />;
  }

  renderMaterial() {
    const { filtersList, filter, onSelectButtonChange } = this.props;
    return <MaterialFilter filtersList={filtersList} filter={filter} onFilterChange={onSelectButtonChange} />;
  }

  renderCondition() {
    const { filtersList, filter, onSelectButtonChange } = this.props;
    return <ConditionFilter filtersList={filtersList} filter={filter} onFilterChange={onSelectButtonChange} />;
  }

  renderCurrency() {
    const { filtersList, filter, onFilterChange } = this.props;
    return <CurrencyFilter filtersList={filtersList} filter={filter} onFilterChange={onFilterChange} />;
  }

  renderTotalPriceFilter() {
    const { filter, filterErrors } = this.props;
    return (<TotalPriceFilter
      filter={filter}
      filterErrors={filterErrors}
      onInputChange={this.handleNumberParamsChange}
    />);
  }

  renderSquarePriceFilter() {
    const { filter, filterErrors } = this.props;
    return (<SquarePriceFilter
      filter={filter}
      filterErrors={filterErrors}
      onInputChange={this.handleNumberParamsChange}
    />);
  }

  renderLandAreaPriceFilter() {
    const { filter, filterErrors } = this.props;
    return (<LandAreaPriceFilter
      filter={filter}
      filterErrors={filterErrors}
      onInputChange={this.handleNumberParamsChange}
    />);
  }

  renderAreaFilter(placeholder) {
    const { filter, filterErrors } = this.props;
    return (<AreaFilter
      filter={filter}
      filterErrors={filterErrors}
      onInputChange={this.handleNumberParamsChange}
      placeholder={placeholder}
    />);
  }

  renderFlooringFilter() {
    const { filter, filterErrors } = this.props;
    return <FlooringFilter filter={filter} filterErrors={filterErrors} onInputChange={this.handleNumberParamsChange} />;
  }

  renderFloorFilter() {
    const { filter, filterErrors } = this.props;
    return <FloorFilter filter={filter} filterErrors={filterErrors} onInputChange={this.handleNumberParamsChange} />;
  }

  renderLandAreaFilter() {
    const { filter, filterErrors } = this.props;
    return <LandAreaFilter filter={filter} filterErrors={filterErrors} onInputChange={this.handleNumberParamsChange} />;
  }

  renderKitchenAreaFilter() {
    const { filter, filterErrors } = this.props;
    return (<KitchenAreaFilter
      filter={filter}
      filterErrors={filterErrors}
      onInputChange={this.handleNumberParamsChange}
    />);
  }

  renderLivingAreaFilter() {
    const { filter, filterErrors } = this.props;
    return (<LivingAreaFilter
      filter={filter}
      filterErrors={filterErrors}
      onInputChange={this.handleNumberParamsChange}
    />);
  }

  renderCitiesFilter() {
    const { citiesList, filter, onCityChange, onDistrictChange } = this.props;
    return (<CitiesFilter
      citiesList={citiesList}
      filter={filter}
      onCityChange={onCityChange}
      onDistrictChange={onDistrictChange}
    />);
  }

  render() {
    const { filtersList, filter } = this.props;

    const isMortgageVisible =
     filter.objectServiceType && filter.objectServiceType === filtersList.objectServiceType.BUY;

    switch (filter.objectType) {
    case filtersList.objectType.APARTMENT:
      return (
        <Fragment>
          {this.renderServiceTypeFilter()}
          {this.renderSubtypeFilter()}
          {isMortgageVisible && this.renderMortgageFilter()}
          {this.renderFirstFloorFilter()}
          {this.renderLastFloorFilter()}
          {this.renderFloorFilter()}
          {this.renderRoomsCount()}
          {this.renderCurrency()}
          {this.renderTotalPriceFilter()}
          {this.renderSquarePriceFilter()}
          {this.renderAreaFilter()}
          {this.renderKitchenAreaFilter()}
          {this.renderLivingAreaFilter()}
          {this.renderCondition()}
          {this.renderCitiesFilter()}
          {this.renderMaterial()}
        </Fragment>
      );
    case filtersList.objectType.HOUSE_OR_COUNTRY_HOUSE:
      return (
        <Fragment>
          {this.renderServiceTypeFilter()}
          {this.renderSubtypeFilter()}
          {isMortgageVisible && this.renderMortgageFilter()}
          {this.renderFirstFloorFilter()}
          {this.renderLastFloorFilter()}
          {this.renderFlooringFilter()}
          {this.renderRoomsCount()}
          {this.renderCurrency()}
          {this.renderTotalPriceFilter()}
          {this.renderSquarePriceFilter()}
          {this.renderAreaFilter()}
          {this.renderKitchenAreaFilter()}
          {this.renderLivingAreaFilter()}
          {this.renderLandAreaFilter()}
          {this.renderCitiesFilter()}
          {this.renderMaterial()}
          {this.renderCondition()}
        </Fragment>
      );
    case filtersList.objectType.COMMERCIAL:
      return (
        <Fragment>
          {this.renderPartialServiceTypeFilter()}
          {this.renderSubtypeFilter()}
          {this.renderFirstFloorFilter()}
          {this.renderLastFloorFilter()}
          {this.renderFloorFilter()}
          {this.renderCurrency()}
          {this.renderTotalPriceFilter()}
          {this.renderSquarePriceFilter()}
          {this.renderAreaFilter()}
          {this.renderCitiesFilter()}
          {this.renderMaterial()}
          {this.renderCondition()}
        </Fragment>
      );
    case filtersList.objectType.LAND_PLOT:
      return (
        <Fragment>
          {this.renderPartialServiceTypeFilter()}
          {this.renderCurrency()}
          {this.renderTotalPriceFilter()}
          {this.renderLandAreaPriceFilter()}
          {this.renderAreaFilter(i18n.t('LAND_AREA_PLACEHOLDER'))}
          {this.renderCitiesFilter()}
        </Fragment>
      );
    case filtersList.objectType.GARAGE_PARKING:
      return (
        <Fragment>
          {this.renderPartialServiceTypeFilter()}
          {this.renderSubtypeFilter()}
          {this.renderCurrency()}
          {this.renderTotalPriceFilter()}
          {this.renderCitiesFilter()}
        </Fragment>
      );
    default:
      return (
        <Fragment>
        </Fragment>
      );
    }
  }
}

export default FiltersByType;

FiltersByType.propTypes = {
  filtersList: PropTypes.object.isRequired,
  filter: PropTypes.object.isRequired,
  filterErrors: PropTypes.object.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  onInputChange: PropTypes.func.isRequired,
  onCityChange: PropTypes.func.isRequired,
  onDistrictChange: PropTypes.func.isRequired,
  onSelectButtonChange: PropTypes.func.isRequired,
  citiesList: PropTypes.array.isRequired,
};
