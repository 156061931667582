import { connect } from 'react-redux';

import MyObjectsPage from '../components/pages/MyObjectsPage/MyObjectsPage';
import { deleteObject, fetchMyObjects, fetchTagsList } from 'realtor/actions';

const mapStateToProps = (state) => {
  const { appConfigurations, myObjectsList, tag } = state;

  return {
    paramsList: appConfigurations.objectFilters,
    exchangeRates: appConfigurations.exchangeRates,
    objectList: myObjectsList.list,
    totalCount: myObjectsList.totalCount,
    inProgress: myObjectsList.inProgress,
    listInProgress: myObjectsList.listInProgress,
    citiesList: appConfigurations.citiesList,
    isOwnerList: true,
    tagsList: tag.tagsList,
  };
};

const mapDispatchToProps = {
  fetchMyObjects,
  fetchTagsList,
  onDelete: deleteObject,
};

export default connect(mapStateToProps, mapDispatchToProps)(MyObjectsPage);
