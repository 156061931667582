import ObjectResource from '../resourses/Object.resource';

export const FETCH_OBJECTS_START = 'FETCH_OBJECTS_START';
export const FETCH_OBJECTS_FAIL = 'FETCH_OBJECTS_FAIL';
export const FETCH_OBJECTS_SUCCESS = 'FETCH_OBJECTS_SUCCESS';

export const FETCH_OBJECTS_TOTAL_START = 'FETCH_OBJECTS_TOTAL_START';
export const FETCH_OBJECTS_TOTAL_FAIL = 'FETCH_OBJECTS_TOTAL_FAIL';
export const FETCH_OBJECTS_TOTAL_SUCCESS = 'FETCH_OBJECTS_TOTAL_SUCCESS';

const fetchTotalStart = () => {
  return { type: FETCH_OBJECTS_TOTAL_START };
};

const fetchTotalFail = (error) => {
  return { type: FETCH_OBJECTS_TOTAL_FAIL, error };
};

const fetchTotalSuccess = (data) => {
  return { type: FETCH_OBJECTS_TOTAL_SUCCESS, data };
};

const fetchObjectsStart = () => {
  return { type: FETCH_OBJECTS_START };
};

const fetchObjectsFail = (error) => {
  return { type: FETCH_OBJECTS_FAIL, error };
};

const fetchObjectsSuccess = (data) => {
  return { type: FETCH_OBJECTS_SUCCESS, data: {
    ...data,
  } };
};

const mockedObjects = [
  {
    type: 1,
    title: 'Квартира новая',
    images: [],
    city: { name: 'Киев' },
    subtype: 1,
    rooms: 3,
    square: 59,
    totalPriceUah: 500,
  },
  {
    type: 1,
    title: 'Квартира кошерная',
    images: [],
    city: { name: 'Киев' },
    subtype: 2,
    rooms: 3,
    square: 59,
    totalPriceUah: 500,
  },
  {
    type: 2,
    title: 'Квартира бомжатня',
    images: [],
    city: { name: 'Киев' },
    subtype: 1,
    rooms: 3,
    square: 59,
    totalPriceUah: 500,
  },
  {
    type: 1,
    title: 'Квартира бомжатня',
    images: [],
    city: { name: 'Киев' },
    subtype: 2,
    rooms: 3,
    square: 59,
    totalPriceUah: 500,
  },
];

export const fetchObjects = (params) => {
  return (dispatch) => {
    dispatch(fetchObjectsStart());
    ObjectResource.getObjectsList(params)
      .then(() => {
        return dispatch(fetchObjectsSuccess({ list: mockedObjects, total: mockedObjects.length }));
      })
      .catch((error) => {
        return dispatch(fetchObjectsFail(error));
      });
  };
};

export const fetchObjectsTotal = (params) => {
  return (dispatch, getState) => {
    dispatch(fetchTotalStart());
    dispatch(fetchObjectsStart());
    ObjectResource.getObjectsTotal()
      .then((data) => {
        dispatch(fetchTotalSuccess(data));
        if (data.total) fetchObjects(params)(dispatch, getState);
      })
      .catch((error) => {
        return dispatch(fetchTotalFail(error));
      });
  };
};
