import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import i18n from '../../../../../common/i18n';
import PropTypes from 'prop-types';
import ObjectListContent from 'realtor/components/common/ObjectListContent/ObjectListContent';

const ORDER_LIST = {
  FROM_NEWEST_TO_OLDER: {
    order: 'created_at',
    dir: 'DESC',
  },
  FROM_OLDER_TO_NEWEST: {
    order: 'created_at',
    dir: 'ASC',
  },
  MY_FIRST: {
    order: 'my_first',
    dir: 'DESC',
  },
};

const ORDER_KEYS = Object.keys(ORDER_LIST);

export const SearchObjectPage = (props) => {
  const {
    fetchObjects,
    classes,
    ...otherProps
  } = props;

  const handleFetchObjects = (params) => {
    fetchObjects(params);
  };
  return (
    <div>
      <ObjectListContent
        classes={classes}
        {...otherProps}
        fetchTotalAndList={fetchObjects}
        fetchObjects={handleFetchObjects}
        pageTitle={i18n.t('SEARCH_OBJECT_PAGE_TITLE_2')}
        isCreateButtonDisabled={true}
        orderList={ORDER_LIST}
        orderKeys={ORDER_KEYS}
      />
    </div>
  );
};

SearchObjectPage.propTypes = {
  classes: PropTypes.object,
  isOwnerList: PropTypes.bool,
  objectList: PropTypes.array,
  citiesList: PropTypes.array,
  fetchObjects: PropTypes.func,
  totalListCount: PropTypes.number,
  totalCount: PropTypes.number,
  inProgress: PropTypes.bool,
  listInProgress: PropTypes.bool,
  paramsList: PropTypes.object,
};

export default withStyles(styles)(SearchObjectPage);
