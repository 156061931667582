import React, { PureComponent } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import styles from './styles';
import ClientCard from '../ClientCard/ClientCard';

class ClientsList extends PureComponent {
  handleDelete = (client) => {
    const { onDelete } = this.props;

    return onDelete(client);
  }

  renderClientsList() {
    const {
      clients,
      paramsList,
      onDeleteFilter,
      onSelectTag,
      isOwnerList,
      onAddToFavorite,
      onRemoveFromFavorite,
    } = this.props;

    return clients.map((client) => {
      return (
        <ClientCard
          key={`${client.id}_${client.isFavorite}`}
          client={client}
          onDelete={this.handleDelete}
          paramsList={paramsList}
          onDeleteFilter={onDeleteFilter}
          onTagClick={onSelectTag}
          isOwnerList={isOwnerList}
          onAddToFavorite={onAddToFavorite}
          onRemoveFromFavorite={onRemoveFromFavorite}
        />
      );
    });
  }

  render() {
    return this.renderClientsList();
  }
}

export default withStyles(styles)(ClientsList);

ClientsList.propTypes = {
  clients: PropTypes.array,
  onDelete: PropTypes.func,
  onDeleteFilter: PropTypes.func,
  paramsList: PropTypes.object,
  onSelectTag: PropTypes.func,
  isOwnerList: PropTypes.bool.isRequired,
  onAddToFavorite: PropTypes.func,
  onRemoveFromFavorite: PropTypes.func,
};
