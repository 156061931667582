import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import i18n from '../../../../../../../common/i18n';
import LogoutDialog from 'realtor/components/dialogs/LogoutDialog/LogoutDialog';

export const ProfileData = ({ userProfile, classes, onToggleEdit, logout }) => {
  const [isLogoutConfirmationOpen, setIsLogoutConfirmationOpen] = useState(false);

  const handleLogoutToggle = () => {
    setIsLogoutConfirmationOpen(!isLogoutConfirmationOpen);
  };

  let tableData = [
    [i18n.t('FIRST_NAME'), userProfile.firstName],
    [i18n.t('LAST_NAME'), userProfile.lastName],
  ];

  if (userProfile.organization) {
    tableData.push([i18n.t('ORGANIZATION'), userProfile.organization]);
  }

  tableData = [
    ...tableData,
    [i18n.t('MAIN_TELEPHONE_NUMBER'), userProfile.telephoneNumber.getTelephoneNumberStringForForm()],
    ...userProfile.moreTelephoneNumbers.map((telephoneNumber, index) => {
      return [`${i18n.t('TELEPHONE_NUMBER')} ${index + 1}`, telephoneNumber.getTelephoneNumberStringForForm()];
    }),
  ];

  return (
    <div>
      <TableContainer className={classes.profileTable}>
        <Table aria-label="simple table">
          <TableBody>
            {tableData.map((row, rowKey) => {
              return (
                <TableRow key={rowKey}>
                  <TableCell><b>{row[0]}</b></TableCell>
                  <TableCell>{row[1]}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <div className={classes.controlsBlock}>
        <Button
          fullWidth
          variant="contained"
          color="default"
          className={classes.editButton}
          onClick={onToggleEdit}
        >
          {i18n.t('EDIT')}
        </Button>
        <Button
          fullWidth
          variant="contained"
          color="default"
          className={classes.editButton}
          onClick={handleLogoutToggle}
        >
          {i18n.t('LOGOUT')}
        </Button>
      </div>
      <LogoutDialog
        onConfirm={logout}
        onDialogClose={handleLogoutToggle}
        isDeleteDialogOpen={isLogoutConfirmationOpen}
      />
    </div>

  );
};

ProfileData.propTypes = {
  userProfile: PropTypes.object,
  classes: PropTypes.object,
  onToggleEdit: PropTypes.func,
  logout: PropTypes.func,
};

export default withStyles(styles)(ProfileData);
