import { connect } from 'react-redux';
import { Cabinet } from '../components/Cabinet/Cabinet/Cabinet';
import styles from '../components/Cabinet/Cabinet/styles';
import { withStyles } from '@material-ui/core/styles';
import { fetchShareToken } from '../actions/objects';
import { fetchUserInfo } from '../actions/user';
import { logout } from '../allAccess/actions/authorization';
import { compose } from 'recompose';
import { ShareTokenHelper } from '../helpers/ShareTokenHelper';
import { isProfileFilled } from '../realtor/selectors/User/isProfileFilled';
import { redirectTo } from '../actions/redirect';


const mapStateToProps = (state) => {
  const { myObjects, user, appConfigurations } = state;

  return {
    shareLink: myObjects.shareToken ? ShareTokenHelper.buildShareLinkForObjectsList(myObjects.shareToken) : null,
    isProfileFilled: isProfileFilled(state),
    inProgress: user.inProgress,
    isMobile: appConfigurations.isMobile,
    userProfile: user.profile,
  };
};

const mapDispatchToProps = {
  fetchShareToken,
  fetchUserInfo,
  logout,
  redirectTo,
};

export default compose(
  withStyles(styles, {
    name: 'Cabinet',
  }),
  connect(mapStateToProps, mapDispatchToProps)
)(Cabinet);
