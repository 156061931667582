/* eslint-disable import/max-dependencies */
import React, { PureComponent } from 'react';
import PropTypes, { bool } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import 'react-s-alert/dist/s-alert-default.css';
import styles from './styles';
import {
  Container,
} from '@material-ui/core';
import {
  BrowserRouter as Router,
} from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';

import Header from '../Header/Header';
import ObjectsListPage from '../../../containers/ObjectsListPage';
import ShareObjectsDialog from '../../Dialogs/ShareObjectsDialog/ShareObjectsDialog';
import AccountConfirmationRoutes from '../../../realtor/routes/AccountConfirmationRoutes';
import NavigationBar from '../../../realtor/components/layears/NavigationBar/NavigationBar';
import { RealtorRoutes } from '../../../realtor/routes/RealtorRoutes';
import { CircularProgressBox } from '../../../realtor/components/layears/CircularProgressBox/CircularProgressBox';
import MobileNavigationBar from '../../../realtor/components/layears/MobileNavigationBar/MobileNavigationBar';
import clsx from 'clsx';

const accountMenuId = 'account-menu';

export class Cabinet extends PureComponent {
  constructor(props) {
    super(props);
    props.fetchShareToken();
    props.fetchUserInfo();
  }

  state = {
    isMenuOpen: false,
    isShareLinkDialogOpen: false,
    isMenuOpenFromMobile: false,
  }

  static getDerivedStateFromProps(props, state) {
    if (props.redirectPath) {
      props.redirectTo(null);
    }

    return state;
  }

  handleMenuToggle = (isMenuOpenFromMobile) => {
    this.setState({
      ...this.state,
      isMenuOpen: !this.state.isMenuOpen,
      isMenuOpenFromMobile,
    });
  }

  handleShareLinkDialogClose = () => {
    this.setState({
      ...this.state,
      isShareLinkDialogOpen: false,
    });
  }

  handleShareLinkDialogOpen = () => {
    this.setState({
      ...this.state,
      isShareLinkDialogOpen: true,
    });
  }

  renderToolbar() {
    const { classes } = this.props;
    return (
      <Header
        classes={classes}
        onDrawerToggle={this.handleDrawerToggle}
        isMenuOpen={this.state.isMenuOpen}
        onShareLinkDialogOpen={this.handleShareLinkDialogOpen}
        accountMenuId={accountMenuId}
        onProfileMenuOpen={this.handleAccountMenuOpen}
      />
    );
  }

  renderNavigation() {
    const { isMenuOpen, isMenuOpenFromMobile } = this.state;
    const { userProfile, logout } = this.props;

    return (
      <NavigationBar
        isMenuOpen={isMenuOpen}
        onMenuToggle={this.handleMenuToggle}
        isMenuOpenFromMobile={isMenuOpenFromMobile}
        userProfile={userProfile}
        logout={logout}
      />
    );
  }

  renderContent(){
    const {
      classes,
      shareLink,
      redirectPath,
    } = this.props;

    const { isMenuOpen } = this.state;

    return (
      <Router indexRoute={ObjectsListPage}>
        <MobileNavigationBar
          onMenuToggle={this.handleMenuToggle}
        />
        <div
          className={clsx({
            [classes.mobileContentBackground]: isMenuOpen,
          })}
          onClick={this.handleMenuToggle}
        />
        <div className={classes.root}>
          <CssBaseline />
          {this.renderNavigation()}
          <Container className={classes.content}>
            <RealtorRoutes redirectPath={redirectPath}/>
          </Container>
        </div>
        <ShareObjectsDialog
          isDialogOpen={this.state.isShareLinkDialogOpen}
          shareLink={shareLink}
          onDialogClose={this.handleShareLinkDialogClose}
        />
      </Router>
    );
  }

  renderNotConfirmedContent() {
    return (
      <AccountConfirmationRoutes />
    );
  }

  render() {
    const { isProfileFilled, isAppInitialized } = this.props;

    if (!isAppInitialized) {
      return (
        <CircularProgressBox/>
      );
    }

    return isProfileFilled ? this.renderContent() : this.renderNotConfirmedContent();
  };
};

export default withStyles(styles)(Cabinet);

Cabinet.propTypes = {
  classes: PropTypes.object.isRequired,
  fetchShareToken: PropTypes.func.isRequired,
  fetchUserInfo: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  shareLink: PropTypes.string,
  isProfileFilled: bool,
  isAppInitialized: bool,
  redirectPath: PropTypes.string,
  redirectTo: PropTypes.func,
  userProfile: PropTypes.object,
};
