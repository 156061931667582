import React from 'react';
import { Modal, Backdrop, Box, Fade } from '@material-ui/core';
import Slider from '../ImageCarousel/ImageCarousel';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';

const ImageCarouselModal = ({ photos, isOpen, onClose, classes }) => {
  return (
    <div>
      <Modal
        open={isOpen}
        onClose={onClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={isOpen}>
          <Box className={classes.imageCarouselModalBox}>
            <CloseIcon style={
              {
                cursor: 'pointer',
                position: 'absolute',
                right: '5px',
                top: '5px',
                fill: '#000',
              }
            } onClick={onClose} color={'inherit'} fontSize={'large'}/>
            <Slider images={photos}/>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default withStyles(styles)(ImageCarouselModal);

ImageCarouselModal.propTypes = {
  photos: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
