import Alert from 'react-s-alert';

import ClientResource from '../resourses/Client.resource';
import { deleteClientFail, deleteClientStart, deleteClientSuccess } from './clientActions';
import i18n from '../../../common/i18n';
import { Client, serverFilterToDomainFactory, TelephoneNumberBuilder } from '../domain';
import {
  deleteClientFilterError,
  deleteClientFilterStart,
  deleteClientFilterSuccess,
} from 'realtor/actions/clientFilterActions';
import ClientFilterResource from 'realtor/resourses/ClientFilter.resource';

export const FETCH_CLIENTS_START = 'FETCH_CLIENTS_START';
export const FETCH_CLIENTS_FAIL = 'FETCH_CLIENTS_FAIL';
export const FETCH_CLIENTS_SUCCESS = 'FETCH_CLIENTS_SUCCESS';

export const FETCH_TOTAL_START = 'FETCH_TOTAL_START';
export const FETCH_TOTAL_FAIL = 'FETCH_TOTAL_FAIL';
export const FETCH_TOTAL_SUCCESS = 'FETCH_TOTAL_SUCCESS';

const fetchTotalStart = () => {
  return { type: FETCH_TOTAL_START };
};

const fetchTotalFail = (error) => {
  return { type: FETCH_TOTAL_FAIL, error };
};

const fetchTotalSuccess = (data) => {
  return { type: FETCH_TOTAL_SUCCESS, data };
};

const fetchClientsStart = () => {
  return { type: FETCH_CLIENTS_START };
};

const fetchClientsFail = (error) => {
  return { type: FETCH_CLIENTS_FAIL, error };
};

const fetchClientsSuccess = (data, types) => {
  const clientsDomainList = data.list.map((client) => {
    return new Client({
      ...client,
      telephoneNumber: TelephoneNumberBuilder.buildFromServerData(client.telephoneNumber),
      filters: client.filters.map((filter) => {
        return serverFilterToDomainFactory(filter, types);
      }),
    });
  });

  return { type: FETCH_CLIENTS_SUCCESS, data: {
    ...data, list: clientsDomainList,
  } };
};

export const fetchClients = (params) => {
  return (dispatch, getState) => {
    const state = getState();

    dispatch(fetchClientsStart());
    ClientResource.getList(params)
      .then((data) => {
        return dispatch(fetchClientsSuccess(data, state.appConfigurations.objectFilters.objectType));
      })
      .catch((error) => {
        return dispatch(fetchClientsFail(error));
      });
  };
};

export const fetchTotalAndList = (params) => {
  return (dispatch, getState) => {
    dispatch(fetchTotalStart());
    dispatch(fetchClientsStart());
    ClientResource.getTotal()
      .then((data) => {
        dispatch(fetchTotalSuccess(data));
        if (data.total) fetchClients(params)(dispatch, getState);
      })
      .catch((error) => {
        return dispatch(fetchTotalFail(error));
      });
  };
};

export const fetchAllClients = (params) => {
  return (dispatch, getState) => {
    const state = getState();

    dispatch(fetchClientsStart());
    ClientResource.getAllList(params)
      .then((data) => {
        return dispatch(fetchClientsSuccess(data, state.appConfigurations.objectFilters.objectType));
      })
      .catch((error) => {
        return dispatch(fetchClientsFail(error));
      });
  };
};

export const fetchAllTotalAndList = (params) => {
  return (dispatch, getState) => {
    dispatch(fetchTotalStart());
    dispatch(fetchClientsStart());
    ClientResource.getAllTotal()
      .then((data) => {
        dispatch(fetchTotalSuccess(data));
        if (data.total) fetchAllClients(params)(dispatch, getState);
      })
      .catch((error) => {
        return dispatch(fetchTotalFail(error));
      });
  };
};

export const deleteAndFetchList = (client, reloadParams) => {
  return (dispatch, getState) => {
    dispatch(deleteClientStart());
    ClientResource.deleteClient(client.id)
      .then(() => {
        dispatch(deleteClientSuccess());
        Alert.success(
          i18n.t('CLIENT_SUCCESSFULLY_DELETED', { fullName: client.fullName }),
          { position: 'top-right', effect: 'slide', timeout: 3000 }
        );
        fetchClients(reloadParams)(dispatch, getState);
      })
      .catch((error) => {
        dispatch(deleteClientFail(error));
      });
  };
};

export const deleteClientFilterAndFetchList = (clientId, filterId, reloadParams) => {
  return (dispatch, getState) => {
    dispatch(deleteClientFilterStart());
    ClientFilterResource.deleteSingleClientFilter(clientId, filterId)
      .then(() => {
        dispatch(deleteClientFilterSuccess());
        Alert.success(
          i18n.t('CLIENT_FILTER_SUCCESSFULLY_DELETED'),
          { position: 'top-right', effect: 'slide', timeout: 3000 }
        );
        fetchClients(reloadParams)(dispatch, getState);
      })
      .catch((error) => {
        dispatch(deleteClientFilterError(error));
      });
  };
};
