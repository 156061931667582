import React, { Fragment, PureComponent } from 'react';
import { Paper } from '@material-ui/core';
import PropTypes from 'prop-types';

import Pagination from '../../layears/Pagination/Pagination';
import { CircularProgressBox } from '../../layears/CircularProgressBox/CircularProgressBox';
import ObjectsControlPanel from '../../layears/ObjectsControlPanel/ObjectsControlPanel';
import { get, isEmpty } from 'lodash';
import NoObjects from 'realtor/components/common/ObjectListContent/layouts/NoObjects/NoObjects';
import NoObjectsFound from 'realtor/components/common/ObjectListContent/layouts/NoObjectsFound/NoObjectsFound';
import ObjectsPageHeader from 'realtor/components/pages/MyObjectsPage/layouts/ObjectsPageHeader/ObjectsPageHeader';
import ObjectsList from 'realtor/components/layears/ObjectsList/ObjectsList';
import ObjectFiltersBar from 'realtor/components/layears/ObjectFiltersBar/ObjectFiltersBar';
import DeleteRealEstateObjectDialog
  from 'realtor/components/dialogs/DeleteRealEstateObjectDialog/DeleteRealEstateObjectDialog';

const DEFAULT_LIMITS = [10, 30];
const DEFAULT_OFFSET = 0;

const DEFAULT_PAGINATION = {
  limit: DEFAULT_LIMITS[0],
  offset: DEFAULT_OFFSET,
};

export class ObjectListContent extends PureComponent {
  constructor(props) {
    super(props);

    const listFetchParams = {
      ...this.state.listFetchParams,
      order: props.orderKeys[0],
    };

    this.setState({
      ...this.state,
      listFetchParams,
    });

    props.fetchTotalAndList(
      this.getFetchParams(listFetchParams)
    );
  }

  state = {
    listFetchParams: {
      pagination: DEFAULT_PAGINATION,
      filters: {
        search: '',
      },
      paramsFilter: {},
      order: 'FROM_NEWEST_TO_OLDER',
    },
    isFiltersOpen: false,
    realEstateObjectDeleteDialog: {
      realEstateObjectToDelete: null,
      isDeleteDialogOpen: false,
    },
  }

  componentWillUpdate = (nextProps, nextState) => {
    function compareNumbers(a, b) {
      return a - b;
    }

    const checkEqualSets = (next, current,propertyName) => {
      if (next[propertyName] && current[propertyName]) {
        const objectFilterArrayNext = Array.from(next[propertyName]).sort(compareNumbers);
        const objectFilterArrayCurrent = Array.from(current[propertyName]).sort(compareNumbers);

        if (objectFilterArrayNext.length >= objectFilterArrayCurrent.length) {
          return objectFilterArrayNext.every((item, index) => item === objectFilterArrayCurrent[index]);
        }

        return objectFilterArrayCurrent.every((item, index) => item === objectFilterArrayNext[index]);
      }
      return null;
    };

    const propertiesToCheck = [
      'objectCondition',
      'objectMaterial',
      'objectSubtype',
      'roomsCount',
    ];
    const filtersNext = nextState.listFetchParams.paramsFilter;
    const filtersState = this.state.listFetchParams.paramsFilter;
    const checkedSets = propertiesToCheck.map((filter) => checkEqualSets(filtersNext, filtersState, filter));
    const changeDetected = checkedSets.some((equalSet) => equalSet === false);

    if ((JSON.stringify(nextState.listFetchParams) !== JSON.stringify(this.state.listFetchParams)) || changeDetected) {
      this.loadContent(nextState.listFetchParams);
    }
  }


  getFetchParams(params) {
    const tagId = params.filters.tag ? params.filters.tag.id : undefined;
    const { tag, ...filters } = params.filters;

    return {
      ...params.pagination,
      ...filters,
      ...params.paramsFilter,
      ...this.props.orderList[params.order],
      tagId,
    };
  }

  handleFiltersToggle = () => {
    this.setState({
      ...this.state,
      isFiltersOpen: !this.state.isFiltersOpen,
    });
  }

  handleDeleteObjectCancel = () => {
    this.setState({
      ...this.state,
      realEstateObjectDeleteDialog: {
        realEstateObjectToDelete: null,
        isDeleteDialogOpen: false,
      },
    });
  }

  handleDeleteObjectConfirm = () => {
    const { onDelete } = this.props;

    const newState = {
      ...this.state,
      realEstateObjectDeleteDialog: {
        realEstateObjectToDelete: null,
        isDeleteDialogOpen: false,
      },
    };

    onDelete(
      this.state.realEstateObjectDeleteDialog.realEstateObjectToDelete,
      this.getFetchParams(this.state.listFetchParams)
    );
    return this.setState(newState);
  }

  loadContent(params) {
    const { fetchObjects } = this.props;
    fetchObjects(this.getFetchParams(params));
  }

  handleLimitChange = (limit) => {
    const pagination = {
      limit,
      offset: 0,
    };

    this.setState({
      ...this.state,
      listFetchParams: {
        ...this.state.listFetchParams,
        pagination,
      },
    });
  }

  handlePaginationBack = () => {
    const { listFetchParams: { pagination: { offset, limit } } } = this.state;

    const pagination = {
      ...this.state.listFetchParams.pagination,
      offset: offset - limit,
    };

    this.setState({
      ...this.state,
      listFetchParams: {
        ...this.state.listFetchParams,
        pagination,
      },
    });
  }

  handlePaginationForward = () => {
    const { listFetchParams: { pagination: { offset, limit } } } = this.state;
    const pagination = {
      ...this.state.listFetchParams.pagination,
      offset: offset + limit,
    };

    this.setState({
      ...this.state,
      listFetchParams: {
        ...this.state.listFetchParams,
        pagination,
      },
    });
  }

  handleSearchChange = (params) => {
    this.setState({
      ...this.state,
      listFetchParams: {
        ...this.state.listFetchParams,
        pagination: {
          ...this.state.listFetchParams.pagination,
          offset: DEFAULT_OFFSET,
        },
        filters: {
          ...this.state.listFetchParams.filters,
          search: params.search,
          tag: get(params, 'tag', null),
        },
      },
    });
  }

  handleFilterChange = (filters) => {
    return this.setState({
      ...this.state,
      isFiltersOpen: false,
      listFetchParams: {
        ...this.state.listFetchParams,
        paramsFilter: filters,
      },
    });
  }

  handleFilterReset = () => {
    return this.setState({
      ...this.state,
      isFiltersOpen: false,
      listFetchParams: {
        ...this.state.listFetchParams,
        paramsFilter: {},
      },
    });
  }

  handleOrderChange = (order) => {
    return this.setState({
      ...this.state,
      isFiltersOpen: false,
      listFetchParams: {
        ...this.state.listFetchParams,
        order,
      },
    });
  }

  handleDeleteObject = (realEstateObject) => {
    this.setState({
      ...this.state,
      realEstateObjectDeleteDialog: {
        realEstateObjectToDelete: realEstateObject,
        isDeleteDialogOpen: true,
      },
    });
  }

  renderHeader() {
    const { classes, totalCount, pageTitle, isCreateButtonDisabled } = this.props;

    return (<ObjectsPageHeader
      classes={classes}
      totalCount={totalCount}
      title={pageTitle}
      isCreateButtonDisabled={isCreateButtonDisabled}
    />);
  }

  renderEmptyObjects() {
    const { classes } = this.props;

    return <NoObjects classes={classes} />;
  }

  renderNoObjectsFound() {
    const { classes } = this.props;

    return <NoObjectsFound classes={classes} />;
  }

  renderObjectsContent() {
    const { objectList, listInProgress } = this.props;

    if (listInProgress) {
      return this.renderProgress();
    }

    const content = objectList.length ? this.renderObjectsList() : this.renderNoObjectsFound();

    return (
      <Fragment>
        {content}
      </Fragment>
    );
  }

  renderObjectsList() {
    const {
      objectList,
      isOwnerList,
      paramsList,
    } = this.props;

    return (
      <Fragment>
        {this.renderPagination()}
        <ObjectsList
          objects={objectList}
          paramsList={paramsList}
          isOwnerList={isOwnerList}
          onSelectTag={this.handleSearchChange}
          onDelete={this.handleDeleteObject}
        />
        {this.renderPagination(true)}
      </Fragment>
    );
  }

  renderPagination(isBottom = false) {
    const { totalCount } = this.props;
    const { listFetchParams } = this.state;

    return (
      <Pagination
        isBottom={isBottom}
        limitsList={DEFAULT_LIMITS}
        onLimitChange={this.handleLimitChange}
        limit={listFetchParams.pagination.limit}
        offset={listFetchParams.pagination.offset}
        total={totalCount}
        onPaginationBack={this.handlePaginationBack}
        onPaginationForward={this.handlePaginationForward}
      />
    );
  }

  renderProgress() {
    return <CircularProgressBox />;
  }

  renderContent() {
    const {
      classes,
      totalCount,
      paramsList,
      citiesList,
      orderKeys,
      exchangeRates,
      fetchTagsList,
      tagsList,
    } = this.props;
    const {
      isFiltersOpen,
      listFetchParams: { paramsFilter, order, filters: { showClientsWithoutParams } },
      realEstateObjectDeleteDialog: { isDeleteDialogOpen, realEstateObjectToDelete },
    } = this.state;

    const contentStyle = isFiltersOpen ? classes.contentBoxWithOpenedFilter : classes.contentBox;
    const isFiltersButtonActive = !isEmpty(paramsFilter);

    return (
      <Fragment>
        <div className={contentStyle}>
          {this.renderHeader()}
          <Paper className={classes.paper} elevation={0}>
            <ObjectsControlPanel
              onSearchChange={this.handleSearchChange}
              onFiltersToggle={this.handleFiltersToggle}
              isFiltersButtonActive={isFiltersButtonActive}
              orderList={ orderKeys }
              currentOrder={ order }
              onOrderChange={ this.handleOrderChange }
              isFiltersButtonDisabled={showClientsWithoutParams}
              selectedTag={this.state.listFetchParams.filters.tag}
              fetchTagsList={fetchTagsList}
              tagsList={tagsList}
            />
            {totalCount ? this.renderObjectsContent() : this.renderEmptyObjects()}
          </Paper>
        </div>
        <DeleteRealEstateObjectDialog
          isDeleteDialogOpen={isDeleteDialogOpen}
          onDialogClose={this.handleDeleteObjectCancel}
          realEstateObject={realEstateObjectToDelete}
          onDeleteConfirm={this.handleDeleteObjectConfirm}
        />
        <ObjectFiltersBar
          paramsList={paramsList}
          exchangeRates={exchangeRates}
          onFiltersToggle={this.handleFiltersToggle}
          isFiltersOpen={isFiltersOpen}
          filtersList={paramsList}
          onConfirm={this.handleFilterChange}
          onReset={this.handleFilterReset}
          citiesList={citiesList}
        />
      </Fragment>
    );
  }

  render() {
    const { inProgress, totalCount } = this.props;
    const isDataLoadInProgress = inProgress || totalCount === null;

    return isDataLoadInProgress ? this.renderProgress() : this.renderContent();
  }
}

ObjectListContent.propTypes = {
  classes: PropTypes.object,
  objectList: PropTypes.array,
  citiesList: PropTypes.array,
  fetchObjects: PropTypes.func,
  fetchTotalAndList: PropTypes.func,
  onDelete: PropTypes.func,
  totalCount: PropTypes.number,
  inProgress: PropTypes.bool,
  listInProgress: PropTypes.bool,
  paramsList: PropTypes.object,
  exchangeRates: PropTypes.object,
  pageTitle: PropTypes.string,
  isCreateButtonDisabled: PropTypes.bool,
  orderKeys: PropTypes.array.isRequired,
  orderList: PropTypes.object.isRequired,
  isOwnerList: PropTypes.bool,
  tagsList: PropTypes.array,
  fetchTagsList: PropTypes.string.isRequired,
};

export default ObjectListContent;
