import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import 'react-s-alert/dist/s-alert-default.css';
import RealEstateObjectContent from 'realtor/components/layears/RealEstateObjectContent/RealEstateObjectContent';
import DeleteObjectDialog from '../../Dialogs/DeleteObjectDialog/DeleteObjectDialog';
import ShareObjectsDialog from '../../Dialogs/ShareObjectsDialog/ShareObjectsDialog';
import { ShareTokenHelper } from '../../../helpers/ShareTokenHelper';
import { setPageTitle } from '../../../../common/helpers/pageTitle';
import i18n from '../../../../common/i18n';

// TODO: Rewrite to functional component
export class ObjectPage extends PureComponent {

  constructor(props) {
    super(props);

    // call api to fetch obj
    this.props.fetchObject(props.realEstateObjectId);
  }

  static getDerivedStateFromProps({ realEstateObject }, state) {
    if (realEstateObject && realEstateObject.title) {
      setPageTitle(
        i18n.t('SHOW_REAL_ESTATE_OBJECT_WINDOW_TITLES', { realEstateObjectTitle: realEstateObject.title })
      );
    }

    return state;
  }

  state = {
    isDeleteDialogOpen: false,
    isShareLinkDialogOpen: false,
    idItemForDelete: null,
    isShareObjectsDialogOpen: false,
  }

  handleDeleteDialogConfirm = () => {
    this.props.deleteObject(this.state.idItemForDelete);
    location.href = '/';
  }

  handleDeleteDialogClose = () => {
    this.setState({
      ...this.state,
      isDeleteDialogOpen: false,
      idItemForDelete: null,
    });
  }

  handleShareObjectsDialogClose = () => {
    this.setState({
      ...this.state,
      isShareObjectsDialogOpen: false,
    });
  }

  handleShareObjectsDialogOpen = () => {
    this.setState({
      ...this.state,
      isShareObjectsDialogOpen: true,
    });
  }

  openDeleteDialog = (idItemForDelete) => {
    this.setState({
      ...this.state,
      isDeleteDialogOpen: true,
      idItemForDelete,
    });
  }

  render() {
    const { realEstateObject, filters, inProgress } = this.props;
    const { isDeleteDialogOpen, isShareObjectsDialogOpen } = this.state;

    return (realEstateObject &&
      <Fragment>
        <RealEstateObjectContent
          realEstateObject={realEstateObject}
          filters={filters}
          inProgress={inProgress}
          onShareObjectsDialogOpen={this.handleShareObjectsDialogOpen}
        />
        <DeleteObjectDialog
          isDeleteDialogOpen={isDeleteDialogOpen}
          onDialogClose={this.handleDeleteDialogClose}
          onDeleteConfirm={this.handleDeleteDialogConfirm}
        />
        <ShareObjectsDialog
          onDialogClose={this.handleShareObjectsDialogClose}
          isDialogOpen={isShareObjectsDialogOpen}
          shareLink={ShareTokenHelper.buildShareLinkForSingleObject(realEstateObject.shareToken)}
        />
      </Fragment>
    );
  }
};

export default ObjectPage;

ObjectPage.propTypes = {
  realEstateObjectId: PropTypes.number.isRequired,
  fetchObject: PropTypes.func.isRequired,
  deleteObject: PropTypes.func.isRequired,
  inProgress: PropTypes.bool.isRequired,
  realEstateObject: PropTypes.object,
  filters: PropTypes.object.isRequired,
};
