/* eslint-disable import/max-dependencies */
import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import 'react-s-alert/dist/s-alert-default.css';
import styles from './styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { getObjectPhoto, IMAGE_SIZE } from '../../../services/imageServise';
import ObjectCard from '../../ObjecCard/ObjectCard';
import ObjectFilters from '../../../../common/components/ObjectFilters/ObjectFilters';
import i18n from '../../../../common/i18n';
import { Link } from 'react-router-dom';
import DeleteObjectDialog from '../../Dialogs/DeleteObjectDialog/DeleteObjectDialog';

const defaultQueryParams = {
  limit: 8,
  offset: 0,
};

export class ObjectsListPage extends PureComponent {

  constructor(props) {
    super(props);
    this.props.clearObjectsList();
    this.props.fetchMyObjects(this.state.queryParams);
  }

  state = {
    activeTab: 0,
    queryParams: defaultQueryParams,
    isDeleteDialogOpen: false,
    idItemForDelete: null,
  }

  fetchContent(params, index) {
    if (index === 0) {
      this.props.fetchMyObjects(params);
    } else {
      this.props.fetchAnyObjects(params);
    }
  }

  reloadContent(index) {
    this.props.clearObjectsList();
    this.setState({
      ...this.state,
      queryParams: defaultQueryParams,
    });
    this.fetchContent(defaultQueryParams, index);
  }

  isLoadMoreButtonVisible = () => {
    const { myObjects } = this.props;
    const { queryParams } = this.state;
    return ((!myObjects.inProgress &&
      queryParams.offset === 0) || queryParams.offset !== 0) &&
      (myObjects.list.length < myObjects.totalCount);
  }

  handleDeleteDialogConfirm = () => {
    this.props.deleteObject(this.state.idItemForDelete);
    this.setState({
      ...this.state,
      idItemForDelete: null,
      isDeleteDialogOpen: false,
      queryParams: defaultQueryParams,
    });
    this.props.clearObjectsList();
    this.props.fetchMyObjects(defaultQueryParams);
  }

  handleDeleteDialogClose = () => {
    this.setState({
      ...this.state,
      isDeleteDialogOpen: false,
      idItemForDelete: null,
    });
  }

  openDeleteDialog = (idItemForDelete) => {
    this.setState({
      ...this.state,
      isDeleteDialogOpen: true,
      idItemForDelete,
    });
  }

  handleFiltersSubmit = (params) => {
    const queryParams = {
      ...defaultQueryParams,
      ...params,
    };
    this.setState({
      ...this.state,
      queryParams,
    });
    this.props.clearObjectsList();
    this.fetchContent(queryParams, this.state.activeTab);
  }

  handleTabChange = (event, newValue) => {
    if (newValue !== this.state.activeTab) {
      this.reloadContent(newValue);
      return this.setState({
        ...this.state,
        queryParams: defaultQueryParams,
        activeTab: newValue,
      });
    }
  }

  handleFilterChange = (params) => {
    const queryParams = {
      ...this.state.queryParams,
      ...params,
    };
    this.setState({
      ...this.state,
      queryParams,
    });
  }

  handleLoadMoreData = () => {
    const queryParams = {
      ...this.state.queryParams,
      offset: this.state.queryParams.offset + this.state.queryParams.limit,
    };
    this.setState({
      ...this.state,
      queryParams,
    });

    this.fetchContent(queryParams, this.state.activeTab);
  }

  renderActions(id) {
    return (
      <Fragment>
        <Button
          component={Link}
          to={`/object/${id}/update`}
          variant="contained"
          size="small"
          color="primary">
          {i18n.t('EDIT')}
        </Button>
        <Button onClick={() => this.openDeleteDialog(id)} variant="contained" size="small" color="secondary">
          {i18n.t('DELETE')}
        </Button>
      </Fragment>
    );
  }

  renderCard() {
    const { myObjects } = this.props;
    const items = myObjects.list.map((item, i) => {
      return (
        <Grid item key={i} sm={6} md={4} lg={3} xl={2} xs={12}>
          <ObjectCard
            actions={this.renderActions(item.id)}
            title={item.title}
            thumbnail={getObjectPhoto(item.thumbnail, IMAGE_SIZE.SMALL)}
            id={item.id}
            url={`/object/${item.id}/show`}
            code={item.code}
          >
          </ObjectCard>
        </Grid>
      );
    });
    return (
      <Fragment>
        {items.map((item) => item)}
      </Fragment>
    );
  }

  renderSharedCard() {
    const { myObjects, classes } = this.props;
    const items = myObjects.list.map((item, i) => {
      return (
        <Grid className={classes.objectCardBox} item key={i} sm={6} md={4} lg={3} xl={2} xs={12}>
          <ObjectCard
            title={item.title}
            thumbnail={getObjectPhoto(item.thumbnail, IMAGE_SIZE.SMALL)}
            id={item.id}
            disableLink={true}
            url={`/public/object/${item.id}`}
            code={item.code}
          >
          </ObjectCard>
        </Grid>
      );
    });
    return (
      <Fragment>
        {items.map((item) => item)}
      </Fragment>
    );
  }

  render() {
    const {
      classes,
      filters,
      regions,
    } = this.props;
    const { myObjects } = this.props;
    return (
      <Fragment>
        <Grid container>
          <Grid sm={12} md={6} lg={6} xl={6} xs={12} item>
            <Tabs
              value={this.state.activeTab}
              indicatorColor="primary"
              textColor="primary"
              onChange={this.handleTabChange}
              aria-label="disabled tabs example"
              disabled={myObjects.inProgress}
            >
              <Tab label={i18n.t('MY')} value={0}/>
              <Tab label={i18n.t('SHARED_OBJECTS')} value={1}/>
            </Tabs>
          </Grid>
          <Grid className={classes.createObjectButtonBlock} item sm={12} md={6} lg={6} xl={6}>
            <Button
              className={classes.createObjectButton}
              component={Link}
              to={'/object/create'}
              variant="contained"
              color="primary"
            >{i18n.t('CREATE_NEW_OBJECT')}
            </Button>
          </Grid>
        </Grid>
        {this.state.activeTab === 0 &&
        <Grid className={classes.tabContent} container alignItems={'center'} index={0}>
          <Grid className={classes.filtersBlock} sm={12} item>
            <ObjectFilters
              filters={filters}
              regions={regions}
              showAccessFilter={true}
              onFilterSubmit={this.handleFiltersSubmit}
            />
          </Grid>
          {this.renderCard()}
        </Grid>
        }
        {
          this.state.activeTab === 1 &&
          <Grid className={classes.tabContent} container alignItems={'center'} index={0}>
            <Grid className={classes.filtersBlock} sm={12} item>
              <ObjectFilters
                filters={filters}
                regions={regions}
                showAccessFilter={false}
                onFilterSubmit={this.handleFiltersSubmit}
              />
            </Grid>
            {this.renderSharedCard()}
          </Grid>
        }
        {(this.isLoadMoreButtonVisible()) &&
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={myObjects.inProgress}
            onClick={this.handleLoadMoreData}
          >
            {i18n.t('LOAD_MORE', { count: defaultQueryParams.limit })}
          </Button>
        }
        <DeleteObjectDialog
          isDeleteDialogOpen={this.state.isDeleteDialogOpen}
          onDialogClose={this.handleDeleteDialogClose}
          onDeleteConfirm={this.handleDeleteDialogConfirm}
        />
      </Fragment>
    );
  }
};

export default withStyles(styles)(ObjectsListPage);

ObjectsListPage.propTypes = {
  classes: PropTypes.object.isRequired,
  fetchMyObjects: PropTypes.func.isRequired,
  fetchAnyObjects: PropTypes.func.isRequired,
  clearObjectsList: PropTypes.func.isRequired,
  deleteObject: PropTypes.func.isRequired,
  myObjects: PropTypes.object.isRequired,
  filters: PropTypes.object.isRequired,
  regions: PropTypes.array.isRequired,
};
