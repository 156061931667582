import { getRealEstateTypeTranslation } from 'realtor/helpers/objectParams';
import { Chip } from '@material-ui/core';
import cn from 'classnames';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';

class ChipByType extends PureComponent {

  renderChipByType = () => {
    const { classes, paramsList, object, isStatic } = this.props;

    const realEstateType = getRealEstateTypeTranslation(paramsList, object.type);

    const chipClasses = [classes.typeChip];

    if (isStatic) {
      chipClasses.push(classes.typeChipStatic);
    }

    switch (object.type) {

    case paramsList.objectType.HOUSE_OR_COUNTRY_HOUSE:
      chipClasses.push(classes.chipHouse);
      break;
    case paramsList.objectType.COMMERCIAL:
      chipClasses.push(classes.chipCommercial);
      break;
    case paramsList.objectType.LAND_PLOT:
      chipClasses.push(classes.chipLand);
      break;
    case paramsList.objectType.GARAGE_PARKING:
      chipClasses.push(classes.garage);
      break;
    default:
      break;
    }

    return <Chip className={cn(chipClasses)} label={realEstateType} size="small"/>;
  }

  render() {
    return this.renderChipByType();
  }

}

ChipByType.propTypes = {
  classes: PropTypes.object.isRequired,
  object: PropTypes.object,
  paramsList: PropTypes.object,
  isStatic: PropTypes.bool,
};

export default withStyles(styles)(ChipByType);
