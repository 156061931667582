import React, { PureComponent } from 'react';
import style from './styles';
import { Button, GridList, GridListTile } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import DeleteImageDialog from '../Dialogs/DeleteImageDialog/DeleteImageDialog';
import { ListManager } from 'react-beautiful-dnd-grid';
import { getObjectPhoto, IMAGE_SIZE } from '../../services/imageServise';
import { DeleteOutline } from '@material-ui/icons';

class UploadedImagesGrid extends PureComponent {
  state = {
    isDeleteDialogOpened: false,
    imageIndexToDelete: null,
  }
  handleDeleteImage = (index) => {
    this.setState({
      ...this.state,
      isDeleteDialogOpened: true,
      imageIndexToDelete: index,
    });
  }

  handleDeleteDialogClose = () => {
    this.setState({
      ...this.state,
      isDeleteDialogOpened: false,
      imageIndexToDelete: null,
    });
  }

  handleDeleteDialogConfirm = () => {
    this.props.onImageDelete(this.state.imageIndexToDelete);
    this.handleDeleteDialogClose();
  }

  getImageListFromBlob() {
    const { images } = this.props;
    return images.map((image, index) => {
      return {
        img: URL.createObjectURL(image),
        index,
      };
    });
  }

  getImagesList() {
    const { images } = this.props;
    return images.map((image, index) => {
      return {
        img: getObjectPhoto(image, IMAGE_SIZE.SMALL),
        index,
      };
    });
  }

  renderTile = (item) => {
    const { classes } = this.props;

    return (
      <GridListTile
        style={{
          marginRight: '24px',
        }
        }
        key={item.img}
        cols={item.cols || 1}
      >
        <Button
          className={classes.deleteImageIcon}
          onClick={() => this.handleDeleteImage(item.index)}
        ><DeleteOutline/>
        </Button>

        <div
          className={classes.imageBox}
          style={{
            backgroundImage: `url(${item.img})`,
          }}
        >
        </div>
      </GridListTile>
    );
  }

  getItems() {
    const { isBlobList } = this.props;
    return isBlobList ? this.getImageListFromBlob() : this.getImagesList();
  }

  render() {
    const { classes, onReorder, images } = this.props;
    const { isDeleteDialogOpened } = this.state;
    const isSmallScreen = window.innerWidth < 750;
    const cols = isSmallScreen ? 'auto' : 3;
    const maxItems = isSmallScreen ? 'auto' : 3;

    return (
      <div className={classes.imageGrid}>
        <GridList cellHeight={160} className={classes.gridList} cols={cols}>
          {images && images.length && <ListManager
            style={{
              gap: '24px',
            }}
            spacing={24}
            items={this.getItems()}
            direction="horizontal"
            maxItems={maxItems}
            render={this.renderTile}
            onDragEnd={onReorder}
          />}
        </GridList>
        <DeleteImageDialog
          isDeleteDialogOpen={isDeleteDialogOpened}
          onDeleteConfirm={this.handleDeleteDialogConfirm}
          onDialogClose={this.handleDeleteDialogClose}
        />
      </div>
    );
  }
}

export default withStyles(style)(UploadedImagesGrid);

UploadedImagesGrid.propTypes = {
  classes: PropTypes.object.isRequired,
  images: PropTypes.array.isRequired,
  onImageDelete: PropTypes.func.isRequired,
  onReorder: PropTypes.func.isRequired,
  isBlobList: PropTypes.bool,
};
