import React, { PureComponent } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {
  AppBar,
  Box,
  IconButton,
  Toolbar,
} from '@material-ui/core';

import { Link as RouterLink } from 'react-router-dom';

import styles from './styles';
import SmallLogo from '../SmallLogo/SmallLogo';
import MenuIcon from '@material-ui/icons/Menu';
import addClientSmallImage from '../../../../../../public/images/add_client_mobile.png';
// import addRealEstateImage from '../../../../../../public/images/add_real_estate_mobile.png';

class MobileNavigationBar extends PureComponent {
  handleDrawerToggle = () => {
    return this.props.onMenuToggle(true);
  };

  render() {
    const { classes } = this.props;

    return (
      <Box sx={{ flexGrow: 1 }}>
        <AppBar className={classes.mobileNavigationBar} position="static">
          <Toolbar>
            <div>
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2 }}
                onClick={this.handleDrawerToggle}
              >
                <MenuIcon />
              </IconButton>
            </div>
            <div className={classes.logo}>
              <SmallLogo/>
            </div>
            <div className={classes.controlButtons}>
              {/* <IconButton className={classes.controlButton}>*/}
              {/*  <img alt={'logo'} width={32} name={'logo'} src={addRealEstateImage}/>*/}
              {/* </IconButton>*/}
              <IconButton component={RouterLink} to={'/clients/create'} className={classes.controlButton}>
                <img alt={'logo'} width={32} name={'logo'} src={addClientSmallImage}/>
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
      </Box>
    );
  }
}

export default withStyles(styles)(MobileNavigationBar);

MobileNavigationBar.propTypes = {
  classes: PropTypes.object.isRequired,
  onMenuToggle: PropTypes.func.isRequired,
};
