import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import ObjectCard from 'realtor/components/layears/ObjectCard/ObjectCard';
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';

class ObjectsListComponent extends PureComponent {
  renderRealEstateObjectsList() {
    const {
      objects,
      classes,
      isOwnerList,
      paramsList,
      onSelectTag,
      onDelete,
    } = this.props;

    return (
      <Grid className={classes.container} container spacing={3}>
        {objects.map((object) =>
          (
            <ObjectCard
              key={`${object.id}_${object.isFavorite}`}
              object={object}
              isOwnerList={isOwnerList}
              paramsList={paramsList}
              onTagClick={onSelectTag}
              onDelete={onDelete}
            />
          ))}
      </Grid>
    );
  }

  render() {
    return this.renderRealEstateObjectsList();
  }
}

ObjectsListComponent.propTypes = {
  classes: PropTypes.object,
  objects: PropTypes.array,
  isOwnerList: PropTypes.bool,
  paramsList: PropTypes.object,
  onSelectTag: PropTypes.func,
  onDelete: PropTypes.func,
};

const ObjectsList = withStyles(styles)(ObjectsListComponent);

export default ObjectsList;


