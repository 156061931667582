export default () => {
  return {
    virtualTourChip: {
      background: '#fff',
      position: 'absolute',
      right: '5px',
      top: '5px',
      zIndex: 1,
      borderRadius: '5px',
      padding: '5px 10px',
      fontWeight: '600',
    },
    objectSliderLink: {
      display: 'block',
      width: '100%',
    },
    objectCard: {
      borderRadius: '8px',
      boxShadow: 'none',
    },
    controls: {
      position: 'absolute',
      right: '16px',
      bottom: '16px',
      overflow: 'hidden',
      borderRadius: '8px',
      background: '#ffffff',
    },
    controlsButton: {
      minWidth: '20px',
      '& .MuiSvgIcon-root': {
        fill: '#767676',
      },
    },
    sliderWrap: {
      position: 'relative',
      borderRadius: '8px',
      display: 'flex',
      overflow: 'hidden',
    },
    sliderImg: {
      width: '100%',
      height: '100%',
    },
    objectTitle: {
      display: 'block',
      fontSize: '14px',
      color: '#5F5F5F',
      fontWeight: 700,
      paddingTop: '10px',
      paddingBottom: '5px',
    },
    objectParams: {
      fontSize: '14px',
      color: '#5F5F5F',
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      marginBottom: '8px',
    },
    objectDescription: {
      fontWeight: 500,
      color: '#000000',
      fontSize: '14px',
      marginBottom: '15px',
    },
    objectPrice: {
      fontSize: '16px',
      marginBottom: '8px',
    },
    locationIcon: {
      color: '#C4C4C4',
    },
    roundDivider: {
      display: 'inline-block',
      width: '6px',
      height: '6px',
      borderRadius: '50%',
      background: '#C4C4C4',
      margin: '0 8px',
    },
  };
};
