import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import styles from './styles';
import { FormValidation } from '../../../../../common/helpers/FormValidation';
import i18n from '../../../../../common/i18n';
import FiltersParamsFactory from './RealEstateObjectParams/RealEstateParamsFactory';
import SelectButtonByFilter from '../../common/SelectButtonByFilter/SelectButtonByFilter';

export class CreateRealEstateObjectForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state.realEstateObject = props.realEstateObject;
  };

  state = {
    realEstateObject: {},
    organizationsList: [],
    errors: {},
  }

  handleSubmit = (e) => {
    const { onConfirm } = this.props;

    e.preventDefault();
    this.setState({
      ...this.state,
      errors: {},
    });

    const isFromValid = this.validateForm();

    if (isFromValid) {
      onConfirm({
        client: this.state.client,
      });
    }

    return false;
  }

  validateForm = () => {

    const errors = {};

    this.setState({
      ...this.state,
      errors,
    });
    return FormValidation.isFormValid(errors);
  }

  renderTypeSelect() {
    const { filtersList, onRealEstateTypeSelect, realEstateObject, disableTypeSelect } = this.props;
    const filterName = 'objectType';
    const buttonTitlePrefix = 'FILTER_REAL_ESTATE_TYPE_';
    const header = i18n.t('SELECT_OBJECT_TYPE');

    return (
      <SelectButtonByFilter
        filter={realEstateObject}
        filtersList={filtersList}
        filterName={filterName}
        buttonTitlePrefix={buttonTitlePrefix}
        header={header}
        onSelect={onRealEstateTypeSelect}
        disabled={disableTypeSelect}
      />
    );
  }

  renderParamsByType() {
    const {
      filtersList,
      citiesList,
      onFilterRealEstateSelect,
      realEstateObject,
      onCityChange,
      onDistrictChange,
      errors,
      onServiceSelect,
      disableTypeSelect,
      onInputChange,
    } = this.props;

    return (<FiltersParamsFactory
      filtersList={filtersList}
      realEstateObject={realEstateObject}
      onSelect={onFilterRealEstateSelect}
      citiesList={citiesList}
      onCityChange={onCityChange}
      onDistrictChange={onDistrictChange}
      onFilterServiceSelect={onServiceSelect}
      errors={errors}
      disableServiceType={disableTypeSelect}
      onInputChange={onInputChange}
    />);
  }

  render() {
    return (
      <div>
        <h2>{i18n.t('INFORMATION_ABOUT_NEW_REAL_ESTATE')}</h2>
        <form>
          {this.renderTypeSelect()}
          {this.renderParamsByType()}
        </form>
      </div>
    );
  }
}

export default withStyles(styles)(CreateRealEstateObjectForm);

CreateRealEstateObjectForm.propTypes = {
  realEstateObject: PropTypes.object,
  errors: PropTypes.object,
  onConfirm: PropTypes.func.isRequired,
  filtersList: PropTypes.object,
  citiesList: PropTypes.array.isRequired,
  onRealEstateTypeSelect: PropTypes.func.isRequired,
  onFilterRealEstateSelect: PropTypes.func.isRequired,
  onCityChange: PropTypes.func.isRequired,
  onDistrictChange: PropTypes.func.isRequired,
  onServiceSelect: PropTypes.func,
  onInputChange: PropTypes.func,
  disableTypeSelect: PropTypes.bool,
};
