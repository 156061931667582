import React, { PureComponent } from 'react';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import { FormHelperText } from '@material-ui/core';
import i18n from '../../../../../common/i18n';

export class SelectButtons extends PureComponent {
  handleSelect = (value) => {
    const { name, onSelect, selectedValue, list } = this.props;
    const isAllSelected = selectedValue && list.length === selectedValue.size;

    if (selectedValue && selectedValue.size === 1 && selectedValue.has(value)) {
      return this.handleSelectAll();
    }

    return () => {
      return onSelect(name, value, isAllSelected);
    };
  }
  handleSelectAll = () => {
    const { name, list, onSelectAll } = this.props;

    const selectedValues = new Set();
    list.forEach(({ value }) => {
      selectedValues.add(value);
    });

    return () => {
      return onSelectAll(name, selectedValues);
    };
  }

  renderButton = ({ title, value }) => {
    const { classes, selectedValue, isMulti, disabled, list } = this.props;
    const isActive = isMulti ?
      selectedValue && selectedValue.has(value) :
      value === selectedValue;

    const isAllSelected = selectedValue && list.length === selectedValue.size;

    const buttonClasses = cn({
      [classes.selectButton]: true,
      [classes.activeSelectButton]: isActive & !isAllSelected,
    });

    return (
      <Button
        className={ buttonClasses }
        variant="contained"
        onClick={this.handleSelect(value)}
        disabled={disabled}
      >
        { title }
      </Button>
    );
  }

  renderAllSelectedButton = () => {
    const { classes, list, selectedValue } = this.props;

    const isActive = selectedValue && list.length === selectedValue.size;

    const buttonClasses = cn({
      [classes.selectButton]: true,
      [classes.activeSelectButton]: isActive,
    });

    return (
      <Button
        className={ buttonClasses }
        variant="contained"
        onClick={this.handleSelectAll()}
        disabled={false}
      >
        { i18n.t('ALL_PARAMETERS') }
      </Button>
    );
  }

  render() {
    const { list, title, classes, header, disabled, isMulti, error } = this.props;

    let actualError = false;

    if (error) {
      actualError = error;
    }

    if (disabled) {
      actualError = i18n.t('CANT_BE_CHANGED');
    }

    return (
      <div className={classes.selectButtonBox}>
        {header && <h3>{ header }</h3>}
        {title && <label> { title } </label>}
        <div className={classes.selectButtonBlock}>
          { isMulti && this.renderAllSelectedButton() }
          { list.map(this.renderButton) }
        </div>
        {actualError &&
          <FormHelperText className={classes.errorText}>
            {actualError}
          </FormHelperText>}
      </div>
    );
  }
}

export default withStyles(styles)(SelectButtons);

SelectButtons.propTypes = {
  classes: PropTypes.object.isRequired,
  list: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  onSelectAll: PropTypes.func,
  selectedValue: PropTypes.any,
  title: PropTypes.string,
  header: PropTypes.string,
  isMulti: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.string,
};
