import {
  ListParamsBuilder,
} from './ListParamsBuilder';

export const getParamViewList = (filter, paramsList) => {
  const listParams = new ListParamsBuilder(filter, paramsList);

  return listParams
    .addSubtype()
    .addMaterial()
    .addCondition()
    .addSquarePrice()
    .addRoomsCount()
    .addTotalArea()
    .addKitchenArea()
    .addLivingArea()
    .addLandArea()
    .addFloor()
    .addFlooring()
    .addIsMortgage()
    .addIgnoreFirstFloor()
    .addIgnoreLastFloor()
    .getList();
};
