import { BaseValidator } from './BaseValidator';
import { FormValidation } from '../../../../../common/helpers/FormValidation';

export class HouseValidator extends BaseValidator{
  validate() {
    const errors = this.initBaseValidation();

    errors.objectSubtype = FormValidation.validateRequired(this.realEstateObject.objectSubtype);

    errors.floor = this.validateFloor();

    return errors;
  }
}
