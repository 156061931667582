import { BaseFilter } from './BaseFilter';

export class CommercialFilter extends BaseFilter {
  get areaFrom() {
    return this.props.areaFrom || 0;
  }

  set areaFrom(areaFrom) {
    this.props.areaFrom = areaFrom;
  }

  get areaTo() {
    return this.props.areaTo;
  }

  set areaTo(areaTo) {
    this.props.areaTo = areaTo;
  }

  get landAreaFrom() {
    return this.props.landAreaFrom;
  }

  set landAreaFrom(landAreaFrom) {
    this.props.landAreaFrom = landAreaFrom;
  }

  get landAreaTo() {
    return this.props.landAreaTo;
  }

  set landAreaTo(landAreaTo) {
    this.props.landAreaTo = landAreaTo;
  }

  get objectSubtype() {
    return this.props.objectSubtype;
  }

  set objectSubtype(objectSubtype) {
    this.props.objectSubtype = objectSubtype;
  }

  get objectMaterial() {
    return this.props.objectMaterial;
  }

  set objectMaterial(objectMaterial) {
    this.props.objectMaterial = objectMaterial;
  }

  get floorFrom() {
    return this.props.floorFrom;
  }
  set floorFrom(floorFrom) {
    this.props.floorFrom = floorFrom;
  }
  get floorTo() {
    return this.props.floorTo;
  }
  set floorTo(floorTo) {
    this.props.floorTo = floorTo;
  }

  get objectCondition() {
    return this.props.objectCondition;
  }

  set objectCondition(objectCondition) {
    this.props.objectCondition = objectCondition;
  }

  get squarePriceFrom() {
    return this.props.squarePriceFrom;
  }

  set squarePriceFrom(squarePriceFrom) {
    this.props.squarePriceFrom = squarePriceFrom;
  }

  get squarePriceTo() {
    return this.props.squarePriceTo;
  }

  set squarePriceTo(squarePriceTo) {
    this.props.squarePriceTo = squarePriceTo;
  }

  get objectPartialServiceType() {
    return this.props.objectPartialServiceType;
  }

  set objectPartialServiceType(objectPartialServiceType) {
    this.props.objectPartialServiceType = objectPartialServiceType;
  }

  get ignoreFirstFloor() {
    return this.props.ignoreFirstFloor;
  }

  set ignoreFirstFloor(ignoreFirstFloor) {
    this.props.ignoreFirstFloor = ignoreFirstFloor;
  }

  get ignoreLastFloor() {
    return this.props.ignoreLastFloor;
  }

  set ignoreLastFloor(ignoreLastFloor) {
    this.props.ignoreLastFloor = ignoreLastFloor;
  }

  /* eslint-disable complexity */
  toServerEntity() {
    return {
      ...this.toBaseServerEntity(),
      realEstateSubtype: this.props.objectSubtype ? Array.from(this.props.objectSubtype) : null,
      areaFrom: this.props.areaFrom ? Number(this.props.areaFrom) : 0,
      areaTo: this.props.areaTo ? Number(this.props.areaTo) : null,
      floorFrom: this.props.floorFrom ? Number(this.props.floorFrom) : null,
      floorTo: this.props.floorTo ? Number(this.props.floorTo) : null,
      squarePriceFrom: this.props.squarePriceFrom ? Number(this.props.squarePriceFrom) : null,
      squarePriceTo: this.props.squarePriceTo ? Number(this.props.squarePriceTo) : null,
      realEstateCondition: this.props.objectCondition ? Array.from(this.props.objectCondition) : null,
      realEstateMaterial: this.props.objectMaterial ? Array.from(this.props.objectMaterial) : null,
      realEstateServiceType: this.props.objectPartialServiceType ? Number(this.props.objectPartialServiceType) : null,
      ignoreLastFloor: this.props.ignoreLastFloor,
      ignoreFirstFloor: this.props.ignoreFirstFloor,
    };
  }
}
