import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import i18n from '../../../../../common/i18n';
import SelectButtonByFilter from '../../common/SelectButtonByFilter/SelectButtonByFilter';
import { FormValidation } from '../../../../../common/helpers/FormValidation';
import FiltersByType from './FiltersByType';
import FiltersDrawer from 'realtor/components/common/FitlersDrawer/FiltersDrawer';

const DEFAULT_FILTER = {
  currency: 0,
};

class FiltersBar extends PureComponent {
  state = {
    filter: DEFAULT_FILTER,
    filterErrors: {},
  }

  handleReset = () => {
    const { onReset } = this.props;

    this.setState({
      ...this.state,
      filter: DEFAULT_FILTER,
      filterErrors: {},
    });

    onReset();
  }

  handleSelectButtonChange = (name, value) => {
    const { filtersList } = this.props;

    const valueForSet = value !== this.state.filter[name] ? value : null;

    const filter = {
      ...this.state.filter,
      [name]: valueForSet,
    };

    if (name === 'objectServiceType' && valueForSet !== filtersList.objectServiceType.BUY) {
      filter.isMortgage = false;
    }

    return this.setState({
      ...this.state,
      filter,
    });
  }

  handleFilterChange = (name, value) => {
    const filter = {
      ...this.state.filter,
      [name]: value,
    };

    return this.setState({
      ...this.state,
      filter,
    });
  }

  handleInputChange = (event) => {
    const { name, value } = event.target;
    this.handleFilterChange(name, value);
  }

  handleConfirm = () => {
    const { onConfirm } = this.props;
    const { filter } = this.state;

    const filterErrors = {};

    const isFormValid = FormValidation.isFormValid(filterErrors);

    return isFormValid ? onConfirm(filter) : this.setState({
      ...this.state,
      filterErrors,
    });
  }

  handleTypeChange = (name, value) => {
    const objectType = value !== this.state.filter.objectType ? value : null;

    return this.setState({
      ...this.state,
      filter: {
        ...DEFAULT_FILTER,
        objectType,
      },
    });
  }

  handleCityChange = (event) => {
    const cityId = get(event, 'cityId', null);
    const districtId = cityId ? this.state.filter.districtId : null;

    this.setState({
      ...this.state,
      filter: {
        ...this.state.filter,
        cityId,
        districtId,
      },
    });
  }

  handleDistrictChange = (data) => {
    this.setState({
      ...this.state,
      filter: {
        ...this.state.filter,
        districtId: data ? data.id : null,
      },
    });
  }

  renderFiltersListByType() {
    const { filtersList, citiesList } = this.props;
    const { filter, filterErrors } = this.state;

    return (
      <FiltersByType
        filtersList={filtersList}
        filter={filter}
        onDistrictChange={this.handleDistrictChange}
        onCityChange={this.handleCityChange}
        onFilterChange={this.handleFilterChange}
        onSelectButtonChange={this.handleSelectButtonChange}
        onInputChange={this.handleInputChange}
        filterErrors={filterErrors}
        citiesList={citiesList}
      />
    );
  }

  renderTypeFilter() {
    const { filtersList } = this.props;
    const { filter } = this.state;

    const filterName = 'objectType';
    const buttonTitlePrefix = 'FILTER_REAL_ESTATE_TYPE_';
    const header = `${i18n.t('PARAMS_FILTER_TYPE')}:`;

    return (
      <SelectButtonByFilter
        filter={filter}
        filtersList={filtersList}
        filterName={filterName}
        buttonTitlePrefix={buttonTitlePrefix}
        header={header}
        onSelect={this.handleTypeChange}
      />
    );
  }
  render() {
    const {
      onFiltersToggle,
      isFiltersOpen,
    } = this.props;

    const typeFilter = this.renderTypeFilter();
    const filtersListByTypeComponent = this.renderFiltersListByType();

    return (
      <FiltersDrawer
        isFiltersOpen={isFiltersOpen}
        onFiltersToggle={onFiltersToggle}
        typeFilterComponent={typeFilter}
        filtersListByTypeComponent={filtersListByTypeComponent}
        onConfirm={this.handleConfirm}
        onReset={this.handleReset}
      />
    );
  }
}

export default FiltersBar;

FiltersBar.propTypes = {
  onFiltersToggle: PropTypes.func.isRequired,
  isFiltersOpen: PropTypes.bool,
  filtersList: PropTypes.object,
  citiesList: PropTypes.array,
  onConfirm: PropTypes.func,
  onReset: PropTypes.func,
};
