import { connect } from 'react-redux';

import { fetchObjects } from 'realtor/actions';
import SearchObjectPage from 'realtor/components/pages/SearchObjectPage/SearchObjectPage';

const mapStateToProps = (state) => {
  const { appConfigurations, objectsList } = state;

  return {
    paramsList: appConfigurations.objectFilters,
    objectList: objectsList.list,
    totalListCount: objectsList.totalListCount,
    totalCount: objectsList.totalCount,
    inProgress: objectsList.inProgress,
    listInProgress: objectsList.listInProgress,
    citiesList: appConfigurations.citiesList,
    isOwnerList: false,
  };
};

const mapDispatchToProps = {
  fetchObjects,
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchObjectPage);
