import React, { Fragment, PureComponent } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import styles from './styles';
import i18n from '../../../../../common/i18n';
import RealtorPageHeader from '../../layears/RealtorPageHeader/RealtorPageHeader';
import { CircularProgressBox } from 'realtor/components/layears/CircularProgressBox/CircularProgressBox';
import { Paper } from '@material-ui/core';
import CreateSingleFilterForm from '../../forms/CreateSingleFilterForm/CreateSingleFilterForm';
import { setPageTitle } from '../../../../../common/helpers/pageTitle';

// TODO: Rewrite to functional component
export class UpdateClientFilterPage extends PureComponent {
  constructor(props) {
    super(props);

    setPageTitle(i18n.t('UPDATE_FILTER'));
    props.fetchClientFilter(props.clientId, props.filterId);
  }

  handleFormConfirm = (formData) => {
    const { clientId, filterId, updateClientFilter } = this.props;
    return updateClientFilter(clientId, filterId, formData);
  }

  renderForm = () => {
    const {
      classes,
      clientFilter,
      appConfigurations,
    } = this.props;

    return (
      <Fragment>
        <Paper className={classes.paper} elevation={0}>
          <div>
            <CreateSingleFilterForm
              onConfirm={this.handleFormConfirm}
              filter={clientFilter.toFormEntity()}
              appConfigurations={appConfigurations}
              confirmButtonText={i18n.t('SAVE')}
              disableTypeSelect={clientFilter && Boolean(clientFilter.objectType)}
            />
          </div>
        </Paper>
      </Fragment>
    );
  }

  renderHeader() {
    const { classes } = this.props;

    return (
      <RealtorPageHeader>
        <div className={classes.headerBox}>
          <h1>{i18n.t('UPDATE_FILTER')}</h1>
        </div>
      </RealtorPageHeader>
    );
  }

  renderLoader = () => {
    return <CircularProgressBox />;
  }

  renderContent = () => {
    const { inProgress, clientFilter } = this.props;
    const showForm = !inProgress && clientFilter;

    return showForm ? this.renderForm() : this.renderLoader();
  }

  render() {
    const { classes } = this.props;

    return (
      <Fragment>
        {this.renderHeader()}
        <Paper className={classes.paper} elevation={0}>
          { this.renderContent() }
        </Paper>
      </Fragment>
    );
  }
}

UpdateClientFilterPage.propTypes = {
  fetchClientFilter: PropTypes.func.isRequired,
  updateClientFilter: PropTypes.func.isRequired,
  clientId: PropTypes.number.isRequired,
  filterId: PropTypes.number.isRequired,
  classes: PropTypes.object.isRequired,
  clientFilter: PropTypes.object,
  appConfigurations: PropTypes.object,
  inProgress: PropTypes.bool,
};

export default withStyles(styles)(UpdateClientFilterPage);
