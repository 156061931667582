import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';

import {
  RoomsCountFilter,
  CurrencyFilter,
  CitiesFilter,
  PartialServiceTypeFilter,
  ServiceTypeFilter,
  MaterialFilter,
  ConditionFilter,
  MortgageFilter,
  FirstFloorFilter,
  LastFloorFilter,
  SubtypeFilter,
} from '../Filters';
import {
  LandAreaPriceRangeFilter,
  FlooringRangeFilter,
  AreaRangeFilter,
  SquarePriceRangeFilter,
  KitchenAreaRangeFilter,
  TotalPriceRangeFilter,
  FloorRangeFilter,
  LivingAreaRangeFilter,
} from 'realtor/components/layears/RangeFilters';

class ObjectFiltersByType extends PureComponent {

  renderServiceTypeFilter() {
    const { filtersList, filter, onSelectButtonChange } = this.props;
    return <ServiceTypeFilter filtersList={filtersList} filter={filter} onFilterChange={onSelectButtonChange}/>;
  }

  renderSubtypeFilter() {
    const { filtersList, filter, onSelectButtonMultipleChange, onMultiSelectAll } = this.props;
    return (
      <SubtypeFilter
        filtersList={filtersList}
        filter={filter}
        onFilterChange={onSelectButtonMultipleChange}
        onMultiSelectAll={onMultiSelectAll}
        isMulti={true}
      />
    );
  }

  renderMortgageFilter() {
    const { filter, onFilterChange } = this.props;
    return <MortgageFilter filter={filter} onFilterChange={onFilterChange}/>;
  }

  renderFirstFloorFilter() {
    const { filter, onFilterChange } = this.props;
    return <FirstFloorFilter filter={filter} onFilterChange={onFilterChange}/>;
  }

  renderLastFloorFilter() {
    const { filter, onFilterChange } = this.props;
    return <LastFloorFilter filter={filter} onFilterChange={onFilterChange}/>;
  }

  renderPartialServiceTypeFilter() {
    const { filtersList, filter, onSelectButtonChange } = this.props;
    return <PartialServiceTypeFilter filtersList={filtersList} filter={filter} onFilterChange={onSelectButtonChange}/>;
  }

  renderRoomsCount() {
    const { filtersList, filter, onSelectButtonMultipleChange, onMultiSelectAll } = this.props;
    return (
      <RoomsCountFilter
        filtersList={filtersList}
        filter={filter}
        onFilterChange={onSelectButtonMultipleChange}
        onMultiSelectAll={onMultiSelectAll}
        isMulti={true}/>
    );
  }

  renderMaterial() {
    const { filtersList, filter, onSelectButtonMultipleChange, onMultiSelectAll } = this.props;
    return (
      <MaterialFilter
        filtersList={filtersList}
        filter={filter}
        onFilterChange={onSelectButtonMultipleChange}
        onMultiSelectAll={onMultiSelectAll}
        isMulti={true}
      />
    );
  }

  renderCondition() {
    const { filtersList, filter, onSelectButtonMultipleChange, onMultiSelectAll } = this.props;
    return (
      <ConditionFilter
        filtersList={filtersList}
        filter={filter}
        onFilterChange={onSelectButtonMultipleChange}
        onMultiSelectAll={onMultiSelectAll}
        isMulti={true}
      />
    );
  }

  renderCurrency() {
    const { filtersList, filter, onFilterChange } = this.props;
    return <CurrencyFilter filtersList={filtersList} filter={filter} onFilterChange={onFilterChange}/>;
  }

  renderTotalPriceRange() {
    const { onMultiSelectAll, filter, filterErrors } = this.props;

    return (
      <TotalPriceRangeFilter
        onSelect={onMultiSelectAll}
        filter={filter}
        errors={filterErrors}
      />
    );
  }

  renderSquarePriceRange() {
    const { onMultiSelectAll, filter, filterErrors } = this.props;

    return (
      <SquarePriceRangeFilter
        onSelect={onMultiSelectAll}
        filter={filter}
        errors={filterErrors}
      />
    );
  }

  landAreaPriceRange() {
    const { onMultiSelectAll, filter, filterErrors } = this.props;


    return (
      <LandAreaPriceRangeFilter
        onSelect={onMultiSelectAll}
        filter={filter}
        errors={filterErrors}
      />
    );
  }

  renderArea() {
    const { onMultiSelectAll, filter, filterErrors } = this.props;

    return (
      <AreaRangeFilter
        filter={filter}
        onSelect={onMultiSelectAll}
        errors={filterErrors}
      />
    );
  }

  renderFlooringRange() {
    const { onMultiSelectAll, filter, filterErrors } = this.props;

    return (
      <FlooringRangeFilter
        filter={filter}
        onSelect={onMultiSelectAll}
        errors={filterErrors}
      />
    );
  }

  renderFloorRange() {
    const { onMultiSelectAll, filter, filterErrors } = this.props;
    return (
      <FloorRangeFilter
        onSelect={onMultiSelectAll}
        filter={filter}
        errors={filterErrors}
      />
    );
  }

  renderKitchenAreaRange() {
    const { onMultiSelectAll, filter, filterErrors } = this.props;

    return (
      <KitchenAreaRangeFilter
        onSelect={onMultiSelectAll}
        filter={filter}
        errors={filterErrors}
      />
    );
  }

  renderLivingAreaRange() {
    const { onMultiSelectAll, filter, filterErrors } = this.props;

    return (
      <LivingAreaRangeFilter
        onSelect={onMultiSelectAll}
        filter={filter}
        errors={filterErrors}
      />
    );
  }

  renderCitiesFilter() {
    const { citiesList, filter, onCityChange, onDistrictChange } = this.props;
    return (<CitiesFilter
      citiesList={citiesList}
      filter={filter}
      onCityChange={onCityChange}
      onDistrictChange={onDistrictChange}
    />);
  }

  render() {
    const { filtersList, filter } = this.props;

    switch (filter.objectType) {
    case filtersList.objectType.APARTMENT:
      return (
        <Fragment>
          {this.renderServiceTypeFilter()}
          {this.renderSubtypeFilter()}
          {this.renderRoomsCount()}
          {this.renderCurrency()}
          {this.renderTotalPriceRange()}
          {this.renderSquarePriceRange()}
          {this.renderArea()}
          {this.renderMortgageFilter()}
          {this.renderFloorRange()}
          {this.renderFirstFloorFilter()}
          {this.renderLastFloorFilter()}
          {this.renderKitchenAreaRange()}
          {this.renderLivingAreaRange()}
          {this.renderCondition()}
          {this.renderMaterial()}
          {this.renderCitiesFilter()}
        </Fragment>
      );
    case filtersList.objectType.HOUSE_OR_COUNTRY_HOUSE:
      return (
        <Fragment>
          {this.renderServiceTypeFilter()}
          {this.renderSubtypeFilter()}
          {this.renderRoomsCount()}
          {this.renderCurrency()}
          {this.renderTotalPriceRange()}
          {this.renderSquarePriceRange()}
          {this.renderArea()}
          {this.renderMortgageFilter()}
          {this.renderFlooringRange()}
          {this.renderFirstFloorFilter()}
          {this.renderLastFloorFilter()}
          {this.renderCondition()}
          {this.renderMaterial()}
          {this.renderCitiesFilter()}
        </Fragment>
      );
    case filtersList.objectType.COMMERCIAL:
      return (
        <Fragment>
          {this.renderPartialServiceTypeFilter()}
          {this.renderSubtypeFilter()}
          {this.renderCurrency()}
          {this.renderTotalPriceRange()}
          {this.renderSquarePriceRange()}
          {this.renderArea()}
          {this.renderFloorRange()}
          {this.renderFirstFloorFilter()}
          {this.renderLastFloorFilter()}
          {this.renderCondition()}
          {this.renderMaterial()}
          {this.renderCitiesFilter()}
        </Fragment>
      );
    case filtersList.objectType.LAND_PLOT:
      return (
        <Fragment>
          {this.renderPartialServiceTypeFilter()}
          {this.renderCurrency()}
          {this.renderTotalPriceRange()}
          {this.renderArea()}
          {this.landAreaPriceRange()}
          {this.renderCitiesFilter()}
        </Fragment>
      );
    case filtersList.objectType.GARAGE_PARKING:
      return (
        <Fragment>
          {this.renderPartialServiceTypeFilter()}
          {this.renderSubtypeFilter()}
          {this.renderCurrency()}
          {this.renderTotalPriceRange()}
          {this.renderArea()}
          {this.renderCitiesFilter()}
        </Fragment>
      );
    default:
      return (
        <Fragment>
        </Fragment>
      );
    }
  }
}

export default ObjectFiltersByType;

ObjectFiltersByType.propTypes = {
  filtersList: PropTypes.object.isRequired,
  filter: PropTypes.object.isRequired,
  filterErrors: PropTypes.object.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  onCityChange: PropTypes.func.isRequired,
  onDistrictChange: PropTypes.func.isRequired,
  onSelectButtonChange: PropTypes.func.isRequired,
  onSelectButtonMultipleChange: PropTypes.func.isRequired,
  onMultiSelectAll: PropTypes.func.isRequired,
  citiesList: PropTypes.array.isRequired,
};
