import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import ConfirmUserProfileForm from '../../forms/ConfirmUserProfileForm/ConfirmUserProfileForm';
import ConfirmPageLayer from '../../layears/ConfirmPageLayer/ConfirmPageLayer';
import FormHeaderLayer from '../../../../../common/components/layers/FormHeaderLayer/FormHeaderLayer';
import i18n from '../../../../i18n';
import { mapProfileConfirmationErrors } from '../../../helpers/errorsMapper';

export class ConfirmProfilePage extends PureComponent {
  state = {
    errors: {},
  }

  static getDerivedStateFromProps(props, state) {
    const { error, errorCodes } = props;
    const errorCode = get(error, 'code');

    const validationErrors = errorCode ? mapProfileConfirmationErrors(errorCodes, error) : {};

    return {
      ...state,
      errors: validationErrors,
    };
  }

  renderHeader = () => {
    return (
      <FormHeaderLayer title={i18n.t('CONFIRM_USER_PROFILE_TITLE')}>
        {`${i18n.t('CONFIRM_USER_PROFILE_SUB_TITLE')} `}
      </FormHeaderLayer>
    );
  }

  render() {
    const {
      confirmRealtorProfile,
      profile,
      fetchOrganizationsList,
      organizationsList,
      clearOrganizationsList,
      dropRealtorProfileErrors,
      inProgress,
    } = this.props;

    const { errors } = this.state;

    return (
      <ConfirmPageLayer>
        {this.renderHeader()}
        <ConfirmUserProfileForm
          onConfirm={confirmRealtorProfile}
          profile={profile}
          onFetchOrganizationsList={fetchOrganizationsList}
          organizationsList={organizationsList}
          onClearOrganizationsList={clearOrganizationsList}
          onDropErrors={dropRealtorProfileErrors}
          errors={errors}
          inProgress={inProgress}
        />
      </ConfirmPageLayer>
    );
  }
}

ConfirmProfilePage.propTypes = {
  confirmRealtorProfile: PropTypes.func.isRequired,
  fetchOrganizationsList: PropTypes.func.isRequired,
  clearOrganizationsList: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  organizationsList: PropTypes.array,
  dropRealtorProfileErrors: PropTypes.func.isRequired,
  error: PropTypes.object,
  errorCodes: PropTypes.object,
  inProgress: PropTypes.bool,
};

