/* eslint-disable max-lines */
/* eslint-disable import/max-dependencies */
import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import 'react-s-alert/dist/s-alert-default.css';
import style from './styles';
import Grid from '@material-ui/core/Grid';
import i18n from '../../../../../common/i18n';
import Typography from '@material-ui/core/Typography';
import { Divider, IconButton } from '@material-ui/core';
import Alert from 'react-s-alert';
import ChipByType from 'realtor/components/layears/ChipByType/ChipByType.';
import { LocationOn } from '@material-ui/icons';
import CopyToClipboardIcon from '../../../../shared/svg/CopyToClipboardIcon/CopyToClipboardIcon';
import { formatPriceNumber } from 'realtor/helpers/formatPriceNubmer';
import ClientContacts from 'realtor/components/layears/ObjectCard/components/ClientContacts/ClientContacts';
import { withRouter } from 'react-router';
import {
  getRealEstateConditionTranslation,
  getRealEstateMaterialTranslation,
  getRealEstateRoomsCountTranslation,
  getRealEstateServiceTypeTranslation,
  getRealEstateSubtypeTranslation,
} from 'realtor/helpers/objectParams';
import { getObjectPhoto, IMAGE_SIZE } from '../../../../services/imageServise';
import ImageCarouselModal from '../../common/ImageCarouselModal/ImageCarouselModal';
import { getCurrencyLabel } from 'realtor/helpers/currency';
import
ShareObjectButton
  from 'realtor/components/layears/RealEstateObjectContent/components/ShareObjectButton/ShareObjectButton';
import
ControlButtons
  from 'realtor/components/layears/RealEstateObjectContent/components/ControlButtons/ControlButtons';
import { DefaultPhoto } from 'realtor/components/layears/RealEstateObjectContent/components/DefaultPhoto/DefaultPhoto';

// TODO: Should be refactored
export class RealEstateObjectComponent extends PureComponent {
  constructor(props) {
    super(props);

    this.handleOpenPhotoModal = this.handleOpenPhotoModal.bind(this);
    this.handleClosePhotoModal = this.handleClosePhotoModal.bind(this);
  }

  state = {
    isPhotoModalOpen: false,
  }

  handleOpenPhotoModal() {
    this.setState({
      ...this.state,
      isPhotoModalOpen: true,
    });
  }

  handleClosePhotoModal() {
    this.setState({
      ...this.state,
      isPhotoModalOpen: false,
    });
  }

  updateWithExistingParams(params, realEstateObject, realEstateVisibleParams) {
    const notEmptyValues = Object.keys(realEstateObject).filter((key) => {
      const currentVal = realEstateObject[key];
      return !(currentVal === null || currentVal === undefined);
    });

    notEmptyValues.forEach((value) => {
      if (realEstateVisibleParams[value]) params.push(realEstateVisibleParams[value]);
    });
  };

  // eslint-disable-next-line
  customObjectParams() {
    const { realEstateObject, filters } = this.props;
    const {
      subtype,
      type,
      roomsCount,
      material,
      condition,
      currency,
    } = realEstateObject;

    const blankSpace = '-';

    const currencyLabel = getCurrencyLabel(currency);

    const subtypeParam = {
      title: i18n.t('PARAMS_FILTER_SUBTYPE_TYPE'),
      value: getRealEstateSubtypeTranslation(filters, type, subtype),
    };
    const livingAreaParam = {
      title: i18n.t('LIVING_AREA'),
      value: (
        <Fragment>
          {realEstateObject.livingArea}
          <span>{i18n.t('AREA_PLACEHOLDER')}</span>
        </Fragment>
      ),
    };
    const kitchenAreaParam = {
      title: i18n.t('KITCHEN_AREA'),
      value: (
        <Fragment>
          {realEstateObject.kitchenArea}
          <span>{i18n.t('AREA_PLACEHOLDER')}</span>
        </Fragment>
      ),
    };
    const squarePriceParam = {
      title: i18n.t('SQUARE_PRICE'),
      value: (
        <Fragment>
          {realEstateObject.squarePrice}
          <span>{currencyLabel}</span>
        </Fragment>
      ),
    };
    const roomsCountParam = {
      title: i18n.t('FILTER_REAL_ESTATE_ROOMS_COUNT'),
      value: getRealEstateRoomsCountTranslation(filters, roomsCount),
    };
    const materialParam = {
      title: i18n.t('FILTER_REAL_ESTATE_MATERIAL'),
      value: getRealEstateMaterialTranslation(filters, material),
    };
    const conditionParam = {
      title: i18n.t('FILTER_REAL_ESTATE_FLOR_CONDITION'),
      value: getRealEstateConditionTranslation(filters, condition),
    };
    const areaParam = {
      title: i18n.t('TOTAL_AREA'),
      value: (
        <Fragment>
          {realEstateObject.area}
          <span>{i18n.t('AREA_PLACEHOLDER')}</span>
        </Fragment>
      ),
    };
    const landAreaParam = {
      title: i18n.t('LAND_AREA'),
      value: (
        <Fragment>{realEstateObject.landArea || blankSpace}{' '}
          <span>{i18n.t('AREA_PLACEHOLDER')}</span>
        </Fragment>
      ),
    };
    const landAreaPriceParam = {
      title: i18n.t('LAND_AREA_PRICE_PLACEHOLDER'),
      value: (
        <Fragment>{realEstateObject.landAreaPrice || blankSpace}{' '}
          <span>{currencyLabel}</span>
        </Fragment>
      ),
    };
    const mortgageParam = {
      title: i18n.t('FILTER_REAL_ESTATE_IS_MORTGAGE'),
      value:
  <Fragment>
    {realEstateObject.isMortgage ?
      i18n.t('FILTER_REAL_ESTATE_IS_MORTGAGE_YES') :
      i18n.t('FILTER_REAL_ESTATE_IS_MORTGAGE_NO')}
  </Fragment>,
    };
    const floorWithFlooringParam = {
      title: `${i18n.t('FLOOR')} / ${i18n.t('FLOORING')}`,
      value: `${realEstateObject.floor}/${realEstateObject.flooring}`,
    };

    switch (realEstateObject.objectType) {

    case filters.objectType.HOUSE_OR_COUNTRY_HOUSE: {
      const params = [subtypeParam];
      const houseParams = {
        livingArea: livingAreaParam,
        kitchenArea: kitchenAreaParam,
        landArea: landAreaParam,
        squarePrice: squarePriceParam,
        roomsCount: roomsCountParam,
        material: materialParam,
        condition: conditionParam,
        isMortgage: mortgageParam,
        floor: floorWithFlooringParam,
        area: areaParam,
      };

      this.updateWithExistingParams(params, realEstateObject, houseParams);

      return params;
    }
    case filters.objectType.APARTMENT: {
      const params = [subtypeParam];
      const apartmentParams = {
        livingArea: livingAreaParam,
        kitchenArea: kitchenAreaParam,
        squarePrice: squarePriceParam,
        roomsCount: roomsCountParam,
        material: materialParam,
        condition: conditionParam,
        isMortgage: mortgageParam,
        floor: floorWithFlooringParam,
        area: areaParam,
      };

      this.updateWithExistingParams(params, realEstateObject, apartmentParams);

      return params;
    }
    case filters.objectType.COMMERCIAL: {
      const params = [subtypeParam];

      const commercialParams = {
        kitchenArea: kitchenAreaParam,
        material: materialParam,
        squarePrice: squarePriceParam,
        roomsCount: roomsCountParam,
        condition: conditionParam,
        floor: floorWithFlooringParam,
        area: areaParam,
      };

      this.updateWithExistingParams(params, realEstateObject, commercialParams);

      return params;
    }
    case filters.objectType.LAND_PLOT: {
      const params = [subtypeParam];

      const landPlotParams = {
        area: areaParam,
        landAreaPrice: landAreaPriceParam,
        landArea: landAreaParam,
      };

      this.updateWithExistingParams(params, realEstateObject, landPlotParams);

      return params;
    }
    case filters.objectType.GARAGE_PARKING: {
      const params = [subtypeParam];

      const garageParkingParams = {
        material: materialParam,
        area: areaParam,
      };

      this.updateWithExistingParams(params, realEstateObject, garageParkingParams);

      return params;
    }
    default:
      return [];
    }
  }

  getObjectParams() {
    const { realEstateObject, filters } = this.props;
    const { objectServiceType, objectPartialServiceType } = realEstateObject;

    const params = [];
    params.push({
      title: i18n.t('PARAMS_FILTER_SERVICE_TYPE'),
      value: getRealEstateServiceTypeTranslation(filters, objectPartialServiceType || objectServiceType),
    });
    return [
      ...params,
      ...this.customObjectParams(),
    ];
  }

  handleCopyCode = () => {
    const { realEstateObject } = this.props;

    navigator.clipboard.writeText(realEstateObject.code);

    Alert.success(
      i18n.t('CODE_COPY_SUCCESS'),
      { position: 'top-right', effect: 'slide', timeout: 3000 }
    );
  }

  handleEditRealEstate = () => {
    const { realEstateObject, history } = this.props;
    const path = `/real-estate-object/${realEstateObject.id}/edit`;

    history.push(path);
  }

  handleGoBack = () => {
    const { history } = this.props;

    history.push('/objects');
  }

  renderObjectParamsList() {
    const params = this.getObjectParams();

    return params
      .filter(({ value }) => Boolean(value))
      .map(({ title, value }, index) => {
        return this.renderObjectParam(title, value, index);
      });
  }

  renderShareButton() {
    const { onShareObjectsDialogOpen } = this.props;

    return (
      <ShareObjectButton onShareObjectsDialogOpen={onShareObjectsDialogOpen} />
    );
  }

  renderObjectParam(title, value, index) {
    const { classes } = this.props;
    return (
      <Grid key={index} className={classes.paramsTitle} item sm={12} md={11} lg={6} xl={6}>
        <p className={classes.rowLabel}>{title}</p>
        <p>{value}</p>
      </Grid>
    );
  }

  renderHeader = () => {
    const { realEstateObject, classes, filters } = this.props;
    return (
      <div className={classes.header}>
        <div className={classes.headerRow}>
          <ChipByType
            object={realEstateObject}
            paramsList={filters}
            isStatic={true}
          />
          <span>
            <cite>{realEstateObject.code}</cite>
            <IconButton onClick={this.handleCopyCode} size={'small'}>
              <CopyToClipboardIcon/>
            </IconButton>
          </span>
        </div>
        <Typography
          className={classes.headerTitle}
          align={'left'}
          variant={'h2'}
        >
          {realEstateObject.title}
        </Typography>
      </div>
    );
  }

  renderLocation = () => {
    const { classes, realEstateObject } = this.props;
    const { city, district } = realEstateObject;
    const districtString = district && district.name && `, ${district.name} ${i18n.t('DISTRICT').toLowerCase()}`;
    return (
      <div className={classes.location}>
        <LocationOn className={classes.locationIcon}/>
        <span>м. {city.name}{districtString}</span>
      </div>
    );
  }

  renderContacts = () => {
    const { classes, realEstateObject } = this.props;
    const { client } = realEstateObject;
    return (
      <div className={classes.objectContact}>
        <ClientContacts client={client}/>
      </div>
    );
  }

  renderPriceBlock = () => {
    const { realEstateObject, classes, hideControlButtons } = this.props;
    const { totalPrice, currency, squarePrice } = realEstateObject;
    const currencyLabel = getCurrencyLabel(currency);
    return (
      <Fragment>
        <div className={classes.objectPrice}>
          <div className={classes.objectPriceData}>
            <strong>{formatPriceNumber(totalPrice)} {currencyLabel}</strong>
            {squarePrice &&
              <p>{i18n.t('REAL_ESTATE_OBJECT_SQUARE_PRICE_LABEL')}: {squarePrice} {currencyLabel} </p>}
          </div>
          {!hideControlButtons && <div>{this.renderShareButton()}</div>}
        </div>
        <Divider/>
      </Fragment>
    );
  }

  renderDefaultPhoto = () => {
    const { classes } = this.props;
    return (
      <DefaultPhoto classes={classes} onEditRealEstate={this.handleEditRealEstate} />
    );
  }

  renderImageBlock = () => {
    const { realEstateObject: { photos }, classes } = this.props;

    return (
      <div className={classes.sliderBox}>
        {photos.length ? this.renderImages() : this.renderDefaultPhoto()}
      </div>
    );
  }

  renderImages = () => {
    const { realEstateObject: { photos }, classes } = this.props;
    const { isPhotoModalOpen } = this.state;
    const photosByOrder = photos.sort((a, b) => a.order - b.order);
    const mainPhoto = getObjectPhoto(photosByOrder[0], IMAGE_SIZE.SMALL);

    let photoToShow;
    if (photos.length > 6) {
      photoToShow = photosByOrder.slice(1, 6);
    } else {
      photoToShow = photosByOrder.slice(1, photosByOrder.length);
    }
    const diff = photosByOrder.length - photoToShow.length - 1;

    return (
      <div>
        <div className={classes.previewMain}>
          <img
            src={mainPhoto}
            className={classes.previewMainImage}
            onClick={this.handleOpenPhotoModal}
          />
        </div>
        <div className={classes.subPreview}>
          {photoToShow.map((photo, index) =>
            (
              <div
                key={index}
                className={classes.subPreviewImage}
                onClick={this.handleOpenPhotoModal}
                style={
                  { backgroundImage: `url(${getObjectPhoto(photo, IMAGE_SIZE.ORIGIN)})` }
                }
              >
                {index === photoToShow.length - 1 && diff && <div className={classes.subPreviewRest}>+{diff}</div>}
              </div>
            )
          )}
        </div>
        <ImageCarouselModal
          isOpen={isPhotoModalOpen}
          photos={photos}
          onClose={this.handleClosePhotoModal}
        />
      </div>
    );
  }

  renderVirtualTour = () => {
    const { realEstateObject: { virtualTourUrl }, classes } = this.props;
    return (
      virtualTourUrl &&
      <div className={classes.iframe}>
        <iframe width={'100%'} height={500} src={virtualTourUrl}></iframe>
      </div>
    );
  }


  renderDescription = () => {
    const { classes, realEstateObject: { description } } = this.props;
    return (
      description &&
      <div className={classes.description}>
        <p className={classes.rowLabel}>{i18n.t('DESCRIPTION')}</p>
        <Typography>{description}</Typography>
      </div>
    );
  }

  renderNotes = () => {
    const { classes, realEstateObject: { notes } } = this.props;
    return (
      notes &&
      <div className={classes.notes}>
        <p className={classes.rowLabel}>{i18n.t('REAL_ESTATE_OBJECT_NOTATIONS')}</p>
        <Typography>{notes}</Typography>
      </div>
    );
  }

  renderTags = () => {
    const { realEstateObject, classes } = this.props;
    const { tags } = realEstateObject;

    return (
      !!tags.length &&
      <Fragment>
        <Divider/>
        <div className={classes.tags}>
          {tags.map((tag) => <span key={tag.title}>{`#${tag.title} `}</span>)}
        </div>
      </Fragment>
    );
  }

  renderActions = () => {
    const { onShareObjectsDialogOpen } = this.props;

    return (<ControlButtons
      onGoBack={this.handleGoBack}
      onShareObjectsDialogOpen={onShareObjectsDialogOpen}
      onEditRealEstate={this.handleEditRealEstate}
    />);
  }

  renderObject() {
    const { classes, hideControlButtons } = this.props;

    return (
      <Grid container className={classes.contentBox}>
        <Grid className={classes.contentBoxItem} item sm={6} md={6} lg={6} xl={6} xs={12}>
          {this.renderImageBlock()}
          {this.renderVirtualTour()}
        </Grid>
        <Grid className={classes.contentBoxItem} item sm={6} md={6} lg={6} xl={6} xs={12}>
          {this.renderHeader()}
          {this.renderPriceBlock()}
          {this.renderLocation()}
          {this.renderContacts()}
          {this.renderDescription()}
          <Grid container className={classes.paramsContainer}>
            {this.renderObjectParamsList()}
          </Grid>

          {this.renderNotes()}
          {this.renderTags()}
          {!hideControlButtons && this.renderActions()}
        </Grid>
      </Grid>
    );
  }

  render() {
    const { realEstateObject } = this.props;
    return (realEstateObject && this.renderObject());
  }
}

const ObjectComponentWithRouter = withRouter(RealEstateObjectComponent);

const RealEstateObjectContent = withStyles(style)(ObjectComponentWithRouter);

export default RealEstateObjectContent;

RealEstateObjectComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object,
  realEstateObject: PropTypes.object.isRequired,
  filters: PropTypes.object.isRequired,
  onShareObjectsDialogOpen: PropTypes.func.isRequired,
  hideControlButtons: PropTypes.bool,
};
