import { connect } from 'react-redux';

import {
  fetchClients,
  fetchTotalAndList,
  deleteAndFetchList,
  deleteClientFilterAndFetchList,
  fetchTagsList,
} from '../actions';
import ClientListPage from '../components/pages/ClientsListPage/ClientListPage';

const mapStateToProps = (state) => {
  const { appConfigurations, clientList, tag } = state;

  return {
    paramsList: appConfigurations.objectFilters,
    clientList: clientList.list,
    totalListCount: clientList.totalListCount,
    totalCount: clientList.totalCount,
    inProgress: clientList.inProgress,
    listInProgress: clientList.listInProgress,
    citiesList: appConfigurations.citiesList,
    tagsList: tag.tagsList,
  };
};

const mapDispatchToProps = {
  fetchClients,
  fetchTotalAndList,
  onDelete: deleteAndFetchList,
  onDeleteClientFilter: deleteClientFilterAndFetchList,
  fetchTagsList,
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientListPage);
