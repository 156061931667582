/* eslint-disable max-lines */
/* eslint-disable import/max-dependencies */
import React, { Fragment, PureComponent } from 'react';
import { Grid, Paper } from '@material-ui/core';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import styles from './styles';

import ClientsList from '../../layears/ClientsList/ClientsList';
import Pagination from '../../layears/Pagination/Pagination';
import { CircularProgressBox } from '../../layears/CircularProgressBox/CircularProgressBox';
import ClientsControlPanel from '../../layears/ClientsControlPanel/ClientsControlPanel';
import {
  DeleteClientDialog,
  DeleteClientFilterDialog,
  DeleteClientFromFavoriteDialog,
} from 'realtor/components/dialogs';
import FiltersBar from '../../layears/FiltersBar/FiltersBar';
import { get, isEmpty } from 'lodash';
import NoClients from 'realtor/components/pages/ClientsListPage/layouts/NoClients/NoClients';
import NoClientsFound from 'realtor/components/pages/ClientsListPage/layouts/NoClientsFound/NoClientsFound';
import PageHeader from 'realtor/components/pages/ClientsListPage/layouts/PageHeader/PageHeader';
import i18n from '../../../../../common/i18n';
import CustomSwitch from 'realtor/components/common/CustomSwitch/CustomSwitch';

const DEFAULT_LIMITS = [10, 30];
const DEFAULT_OFFSET = 0;

const DEFAULT_PAGINATION = {
  limit: DEFAULT_LIMITS[0],
  offset: DEFAULT_OFFSET,
};

export class ClientList extends PureComponent {
  constructor(props) {
    super(props);

    const listFetchParams = {
      ...this.state.listFetchParams,
      order: props.orderKeys[0],
    };

    this.setState({
      ...this.state,
      listFetchParams,
    });

    props.fetchTotalAndList(
      this.getFetchParams(listFetchParams)
    );
  }

  state = {
    listFetchParams: {
      pagination: DEFAULT_PAGINATION,
      filters: {
        search: '',
        showClientsWithoutParams: false,
        showOnlyFavorites: false,
      },
      paramsFilter: {},
      order: 'FROM_NEWEST_TO_OLDER',
    },
    clientDeleteDialog: {
      clientToDelete: null,
      isDeleteDialogOpen: false,
    },
    clientToDeleteFromFavorite: {
      clientToDeleteFromFavorite: null,
      isDeleteFromFavoriteDialogOpen: false,
    },
    clientFilterDeleteDialog: {
      filterId: null,
      clientId: null,
      isFilterDeleteDialogOpen: false,
    },
    isFiltersOpen: false,
  }

  componentWillUpdate = (nextProps, nextState) => {
    if (JSON.stringify(nextState.listFetchParams) !== JSON.stringify(this.state.listFetchParams)) {
      this.loadContent(nextState.listFetchParams);
    }
  }

  getFetchParams(params) {
    const tagId = params.filters.tag ? params.filters.tag.id : undefined;
    const { tag, ...filters } = params.filters;

    return {
      ...params.pagination,
      ...filters,
      ...params.paramsFilter,
      ...this.props.orderList[params.order],
      tagId,
    };
  }

  handleFiltersToggle = () => {
    this.setState({
      ...this.state,
      isFiltersOpen: !this.state.isFiltersOpen,
    });
  }

  handleFilterClose = () => {
    this.setState({
      ...this.state,
      isFiltersOpen: false,
    });
  }

  loadContent(params) {
    const { fetchClients } = this.props;
    fetchClients(this.getFetchParams(params));
  }

  handleLimitChange = (limit) => {
    const pagination = {
      limit,
      offset: 0,
    };

    this.setState({
      ...this.state,
      listFetchParams: {
        ...this.state.listFetchParams,
        pagination,
      },
    });
  }

  handlePaginationBack = () => {
    const { listFetchParams: { pagination: { offset, limit } } } = this.state;

    const pagination = {
      ...this.state.listFetchParams.pagination,
      offset: offset - limit,
    };

    this.setState({
      ...this.state,
      listFetchParams: {
        ...this.state.listFetchParams,
        pagination,
      },
    });
  }

  handlePaginationForward = () => {
    const { listFetchParams: { pagination: { offset, limit } } } = this.state;
    const pagination = {
      ...this.state.listFetchParams.pagination,
      offset: offset + limit,
    };

    this.setState({
      ...this.state,
      listFetchParams: {
        ...this.state.listFetchParams,
        pagination,
      },
    });
  }

  handleSearchChange = (params) => {
    this.setState({
      ...this.state,
      listFetchParams: {
        ...this.state.listFetchParams,
        pagination: {
          ...this.state.listFetchParams.pagination,
          offset: DEFAULT_OFFSET,
        },
        filters: {
          ...this.state.listFetchParams.filters,
          search: params.search,
          tag: get(params, 'tag', null),
        },
      },
    });
  }

  handleDeleteClient = (client) => {
    this.setState({
      ...this.state,
      clientDeleteDialog: {
        clientToDelete: client,
        isDeleteDialogOpen: true,
      },
    });
  }

  handleDeleteClientFilter = (clientId, filterId) => {
    this.setState({
      ...this.state,
      clientFilterDeleteDialog: {
        filterId,
        clientId,
        isFilterDeleteDialogOpen: true,
      },
    });
  }

  handleDeleteClientFromFavoriteFilter = (client) => {
    this.setState({
      ...this.state,
      clientToDeleteFromFavorite: {
        isDeleteFromFavoriteDialogOpen: true,
        clientToDeleteFromFavorite: client,
      },
    });
  }

  handleDeleteClientFromFavoriteCancel = () => {
    this.setState({
      ...this.state,
      clientToDeleteFromFavorite: {
        isDeleteFromFavoriteDialogOpen: false,
        clientToDeleteFromFavorite: null,
      },
    });
  }

  handleDeleteClientFromFavoriteConfirm = () => {
    this.props.onRemoveFromFavorite(this.state.clientToDeleteFromFavorite.clientToDeleteFromFavorite);

    this.setState({
      ...this.state,
      clientToDeleteFromFavorite: {
        isDeleteFromFavoriteDialogOpen: false,
        clientToDeleteFromFavorite: null,
      },
    });
  }

  handleDeleteClientConfirm = () => {
    const { onDelete } = this.props;

    const newState = {
      ...this.state,
      clientDeleteDialog: {
        clientToDelete: null,
        isDeleteDialogOpen: false,
      },
    };

    onDelete(
      this.state.clientDeleteDialog.clientToDelete,
      this.getFetchParams(this.state.listFetchParams)
    );
    return this.setState(newState);
  }

  handleDeleteClientFilterConfirm = () => {
    const { onDeleteClientFilter } = this.props;

    const newState = {
      ...this.state,
      clientFilterDeleteDialog: {
        filterId: null,
        clientId: null,
        isFilterDeleteDialogOpen: false,
      },
    };

    onDeleteClientFilter(
      this.state.clientFilterDeleteDialog.clientId,
      this.state.clientFilterDeleteDialog.filterId,
      this.getFetchParams(this.state.listFetchParams)
    );
    return this.setState(newState);
  }

  handleDeleteClientCancel = () => {
    this.setState({
      ...this.state,
      clientDeleteDialog: {
        clientToDelete: null,
        isDeleteDialogOpen: false,
      },
    });
  }

  handleDeleteClientFilterCancel = () => {
    this.setState({
      ...this.state,
      clientFilterDeleteDialog: {
        filterId: null,
        clientId: null,
        isFilterDeleteDialogOpen: false,
      },
    });
  }

  handleFilterChange = (filters) => {
    return this.setState({
      ...this.state,
      isFiltersOpen: false,
      listFetchParams: {
        ...this.state.listFetchParams,
        paramsFilter: filters,
      },
    });
  }
  handleShowClientsWithoutParamsChange = () => {
    const showClientsWithoutParams = !this.state.listFetchParams.filters.showClientsWithoutParams;

    const filters = {
      ...this.state.listFetchParams.filters,
      showClientsWithoutParams,
    };

    this.setState({
      ...this.state,
      listFetchParams: {
        ...this.state.listFetchParams,
        pagination: DEFAULT_PAGINATION,
        filters,
      },
      isFiltersOpen: false,
    });
  }

  handleShowOnlyFavoritesChange = () => {
    const showOnlyFavorites = !this.state.listFetchParams.filters.showOnlyFavorites;

    const filters = {
      ...this.state.listFetchParams.filters,
      showOnlyFavorites,
    };

    this.setState({
      ...this.state,
      listFetchParams: {
        ...this.state.listFetchParams,
        pagination: DEFAULT_PAGINATION,
        filters,
      },
      isFiltersOpen: false,
    });
  }

  handleFilterReset = () => {
    return this.setState({
      ...this.state,
      isFiltersOpen: false,
      listFetchParams: {
        ...this.state.listFetchParams,
        paramsFilter: {},
      },
    });
  }

  handleOrderChange = (order) => {
    return this.setState({
      ...this.state,
      isFiltersOpen: false,
      listFetchParams: {
        ...this.state.listFetchParams,
        order,
      },
    });
  }

  renderHeader() {
    const { classes, totalCount, pageTitle, isCreateButtonDisabled } = this.props;

    return (<PageHeader
      classes={classes}
      totalCount={totalCount}
      title={pageTitle}
      isCreateButtonDisabled={isCreateButtonDisabled}
    />);
  }

  renderEmptyClients() {
    const { classes } = this.props;

    return <NoClients classes={classes} />;
  }

  renderNoClientsFound() {
    const { classes } = this.props;

    return <NoClientsFound classes={classes} />;
  }

  renderClientsContent() {
    const { clientList, listInProgress } = this.props;

    if (listInProgress) {
      return this.renderProgress();
    }

    const content = clientList.length ? this.renderClientsList() : this.renderNoClientsFound();

    return (
      <Fragment>
        {this.renderTopControls()}
        {content}
      </Fragment>
    );
  }

  renderTopControls() {
    const {
      clientList,
      classes,
      isOwnerList,
    } = this.props;
    const isClientsListNotEmpty = Boolean(clientList.length);
    return (
      <Grid className={classes.controlPanelBox} container>
        <Grid className={classes.controlPanelBox} style={{ padding: '10px 0px' }} item container md={6} xs={12}>
          {isOwnerList ? this.renderWithParamsSwitcher() : this.renderOnlyFavoriteSwitcher()}
        </Grid>
        <Grid item md={6} xs={12}>
          {isClientsListNotEmpty && this.renderPagination()}
        </Grid>
      </Grid>
    );
  }

  renderClientsList() {
    const {
      clientList,
      paramsList,
      isOwnerList,
      onAddToFavorite,
    } = this.props;

    return (
      <Fragment>
        <ClientsList
          onDeleteFilter={this.handleDeleteClientFilter}
          clients={clientList}
          onDelete={this.handleDeleteClient}
          paramsList={paramsList}
          onSelectTag={this.handleSearchChange}
          isOwnerList={isOwnerList}
          onRemoveFromFavorite={this.handleDeleteClientFromFavoriteFilter}
          onAddToFavorite={onAddToFavorite}
        />
        {this.renderPagination()}
      </Fragment>
    );
  }

  renderWithParamsSwitcher() {
    const { listFetchParams } = this.state;
    return (<CustomSwitch
      checked={listFetchParams.filters.showClientsWithoutParams}
      label={i18n.t('CLIENTS_WITHOUT_PARAMS')}
      onChange={this.handleShowClientsWithoutParamsChange}
      name={'showClientsWithoutParams'}
      asButton={true}
    />);
  }

  renderOnlyFavoriteSwitcher() {
    const { listFetchParams } = this.state;
    return (<CustomSwitch
      checked={listFetchParams.filters.showOnlyFavorites}
      label={i18n.t('ONLY_FAVORITES')}
      onChange={this.handleShowOnlyFavoritesChange}
      name={'showOnlyFavorites'}
      asButton={true}
    />);
  }

  renderPagination() {
    const { totalListCount } = this.props;
    const { listFetchParams } = this.state;

    return (
      <Pagination
        limitsList={DEFAULT_LIMITS}
        onLimitChange={this.handleLimitChange}
        limit={listFetchParams.pagination.limit}
        offset={listFetchParams.pagination.offset}
        total={totalListCount}
        onPaginationBack={this.handlePaginationBack}
        onPaginationForward={this.handlePaginationForward}
      />
    );
  }

  renderProgress() {
    return <CircularProgressBox />;
  }

  renderContent() {
    const { classes, totalCount, paramsList, citiesList, fetchTagsList, tagsList, orderKeys } = this.props;
    const {
      clientDeleteDialog,
      clientFilterDeleteDialog,
      clientToDeleteFromFavorite,
      isFiltersOpen,
      listFetchParams: { paramsFilter, order, filters: { showClientsWithoutParams } },
    } = this.state;

    const contentStyle = isFiltersOpen ? classes.contentBoxWithOpenedFilter : classes.contentBox;

    return (
      <Fragment>
        <div className={contentStyle}>
          {this.renderHeader()}
          <Paper className={classes.paper} elevation={0}>
            <ClientsControlPanel
              onSearchChange={this.handleSearchChange}
              onFiltersToggle={this.handleFiltersToggle}
              isFiltersButtonActive={!isEmpty(paramsFilter)}
              orderList={ orderKeys }
              currentOrder={ order }
              onOrderChange={ this.handleOrderChange }
              fetchTagsList={fetchTagsList}
              tagsList={tagsList}
              isFiltersButtonDisabled={showClientsWithoutParams}
              selectedTag={this.state.listFetchParams.filters.tag}
            />
            {totalCount ? this.renderClientsContent() : this.renderEmptyClients()}
          </Paper>
        </div>
        <DeleteClientDialog
          client={clientDeleteDialog.clientToDelete}
          isDeleteDialogOpen={clientDeleteDialog.isDeleteDialogOpen}
          onDeleteConfirm={this.handleDeleteClientConfirm}
          onDialogClose={this.handleDeleteClientCancel}
        />
        <DeleteClientFromFavoriteDialog
          client={clientToDeleteFromFavorite.clientToDeleteFromFavorite}
          isDeleteDialogOpen={clientToDeleteFromFavorite.clientToDeleteFromFavorite}
          onDeleteConfirm={this.handleDeleteClientFromFavoriteConfirm}
          onDialogClose={this.handleDeleteClientFromFavoriteCancel}
        />
        <DeleteClientFilterDialog
          client={clientDeleteDialog.clientToDelete}
          isDeleteDialogOpen={clientFilterDeleteDialog.isFilterDeleteDialogOpen}
          onDeleteConfirm={this.handleDeleteClientFilterConfirm}
          onDialogClose={this.handleDeleteClientFilterCancel}
        />
        <FiltersBar
          onFiltersToggle={this.handleFiltersToggle}
          isFiltersOpen={isFiltersOpen}
          filtersList={paramsList}
          onConfirm={this.handleFilterChange}
          onReset={this.handleFilterReset}
          citiesList={citiesList}
        />
      </Fragment>
    );
  }

  render() {
    const { inProgress, totalCount } = this.props;
    const isDataLoadInProgress = inProgress || totalCount === null;

    return isDataLoadInProgress ? this.renderProgress() : this.renderContent();
  }
}

ClientList.propTypes = {
  classes: PropTypes.object,
  clientList: PropTypes.array,
  citiesList: PropTypes.array,
  tagsList: PropTypes.array,
  fetchClients: PropTypes.func,
  fetchTotalAndList: PropTypes.func,
  totalListCount: PropTypes.number,
  totalCount: PropTypes.number,
  inProgress: PropTypes.bool,
  listInProgress: PropTypes.bool,
  onDelete: PropTypes.func,
  onDeleteClientFilter: PropTypes.func,
  paramsList: PropTypes.object,
  fetchTagsList: PropTypes.string.isRequired,
  pageTitle: PropTypes.string,
  isCreateButtonDisabled: PropTypes.bool,
  isOwnerList: PropTypes.bool.isRequired,
  onAddToFavorite: PropTypes.func,
  onRemoveFromFavorite: PropTypes.func,
  orderKeys: PropTypes.array.isRequired,
  orderList: PropTypes.object.isRequired,
};

export default withStyles(styles)(ClientList);
