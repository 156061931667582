
export default () => {
  return {
    imageCarouselModalBox: {
      position: 'absolute',
      display: 'flex',
      background: '#ffffff',
      justifyContent: 'center',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '90%',
      height: '90%',
      color: '#ffffff',
      boxShadow: '0 0 10px rgba(0,0,0, .5)',
      padding: '40px',
      '&:focus': {
        outline: 'none',
      },
      '@media (max-width: 700px)': {
        width: '100%',
        height: '100%',
        padding: 0,
        paddingTop: '40px',
      },
    },
  };
};
