import React, { PureComponent } from 'react';
import { withStyles } from '@material-ui/core/styles';
import style from './style';
import PropTypes from 'prop-types';
import {
  FormControl,
  FormHelperText,
  TextField,
} from '@material-ui/core';
import i18n from '../../../../../common/i18n';
import { get, set } from 'lodash';
import { Autocomplete } from '@material-ui/lab';

const DISTRICT_NAME = 'district';

class DistrictField extends PureComponent {
  static getDistrictOptions(districtsList) {
    return districtsList.map((district) =>
      ({
        id: district.id,
        name: district.name,
      }));
  }

  state = {
    value: null,
    inputValue: '',
  }

  static getDerivedStateFromProps({ districtsList, selectedDistrict }, prevState) {
    if (!selectedDistrict) {
      const newState = {
        value: null,
      };
      if (!prevState.inputValue) {
        newState.inputValue = '';
      }
      return newState;
    }

    const options = DistrictField.getDistrictOptions(districtsList);
    const selectedOption = options.find((opt) => opt.id === selectedDistrict);

    if (districtsList && districtsList.length && selectedOption.name !== get(prevState, 'value.name')) {
      const value = {
        id: selectedOption.id,
        name: selectedOption.name,
      };
      return {
        value,
      };
    }

    return prevState;
  }

  handleInputValueChange(inputValue) {
    this.setState({
      ...this.state,
      inputValue,
    });
  }

  getFieldLabel() {
    const { districtHeader } = this.props;

    return districtHeader ?
      <h3>{districtHeader}</h3> :
      <label id={'district'}>{i18n.t('DISTRICT')}</label>;
  }

  render()
  {
    const {
      onDistrictChange,
      districtsList,
      error,
      classes,
    } = this.props;

    const {
      value,
      inputValue,
    } = this.state;

    const options = DistrictField.getDistrictOptions(districtsList);
    const label = this.getFieldLabel();

    return (
      <FormControl fullWidth={true}>
        {label}
        <Autocomplete
          value={value}
          onChange={(event, newValue) => onDistrictChange(newValue)}
          className={classes.districtAutocomplete}
          inputValue={inputValue}
          name={DISTRICT_NAME}
          onInputChange={(event, newInputValue) => {
            this.handleInputValueChange(newInputValue);
          }}
          options={options}
          fullWidth
          getOptionLabel={(option) => option.name}
          getOptionSelected={(option, value) => option.id === value.id}
          renderInput={
            (params) => {
              set(params, 'inputProps.autoComplete', 'chrome-off');

              return (
                <TextField
                  variant="outlined"
                  {...params}
                  helperText={error}
                  error={!!error}
                />
              );
            }
          }
        />
        {!!error && <FormHelperText error={error}>{error}</FormHelperText>}
      </FormControl>
    );
  }
}

export default withStyles(style)(DistrictField);

DistrictField.propTypes = {
  districtsList: PropTypes.array,
  onDistrictChange: PropTypes.func,
  selectedDistrict: PropTypes.number,
  error: PropTypes.string,
  districtHeader: PropTypes.string,
  classes: PropTypes.object,
};
