import React, { PureComponent } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import logoImage from '../../../../../../public/images/logo_small.png';
import { Link as RouterLink } from 'react-router-dom';
import { IconButton } from '@material-ui/core';

const styles = () => {};

class SmallLogo extends PureComponent {

  render() {

    const { classes } = this.props;

    return (
      <div className={classes.smallLogo}>
        <IconButton component={RouterLink} to={'/clients'}>
          <img alt={'logo'} width={62} name={'logo'} src={logoImage}/>
        </IconButton>
      </div>
    );
  }
}

export default withStyles(styles)(SmallLogo);

SmallLogo.propTypes = {
  classes: PropTypes.object.isRequired,
};
