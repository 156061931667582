import React, { Fragment, PureComponent } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Accordion, AccordionDetails, AccordionSummary, Card, Grid, IconButton, Typography } from '@material-ui/core';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { Edit, Add, Favorite, FavoriteBorder } from '@material-ui/icons';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Link } from 'react-router-dom';

import styles from './styles';

import tgLogo from '../../../../../../public/images/telegram_logo.png';
import viberLogo from '../../../../../../public/images/viber_logo.png';
import ClientListParam from 'realtor/components/layears/ClientCard/ClientListParam/ClientListParam';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import i18n from '../../../../../common/i18n';

const MAX_COUNT_OF_FILTERS_PARAMS = 3;

class ClientCard extends PureComponent {
  handleDelete = (e) => {
    e.stopPropagation();

    const { onDelete, client } = this.props;
    return onDelete(client);
  }

  renderOwnerControl(params) {
    const { client } = this.props;
    const isAddParamsButtonVisible = (MAX_COUNT_OF_FILTERS_PARAMS > client.filters.length) && params.createLink;

    return (
      <Fragment>
        { isAddParamsButtonVisible &&
          <IconButton component={Link} to={params.createLink}>
            <Add/>
          </IconButton>
        }
        <IconButton component={Link} to={params.editLink} >
          <Edit/>
        </IconButton>
        { params.handleDelete &&
          <IconButton onClick={params.handleDelete}>
            <DeleteOutlineIcon/>
          </IconButton>
        }
      </Fragment>
    );
  }

  renderAllClientsControl() {
    const { client, onAddToFavorite, onRemoveFromFavorite } = this.props;

    return (
      <Fragment>
        <IconButton >
          {client.isFavorite ?
            <Favorite onClick={() => onRemoveFromFavorite(client)} color={'primary'}/> :
            <FavoriteBorder onClick={() => onAddToFavorite(client)} color={'primary'} />}
        </IconButton>
      </Fragment>
    );
  }

  renderFilters(filters, clientId) {
    const { classes, paramsList, onDeleteFilter, isOwnerList } = this.props;

    return filters.map((filter, key) => {
      return (
        <ClientListParam
          filter={filter}
          clientId={clientId}
          classes={classes}
          paramsList={paramsList}
          onDeleteFilter={onDeleteFilter}
          key={key}
          isOwnerList={isOwnerList}
        />
      );
    });
  }

  renderTags(tags) {
    const { classes, onTagClick } = this.props;
    return tags.map((tag) => {
      return <span key={tag.id} className={classes.tag} onClick={() => onTagClick({ tag })}>{`#${tag.title} `}</span>;
    });
  }

  renderOwnClientsHeader(client) {
    const { classes } = this.props;

    return (
      <Accordion>
        <AccordionSummary
          className={classes.clientParamsHeader}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          {this.renderClientCardHeader(client, true)}
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          {client.description || i18n.t('NO_CLIENT_DESCRIPTION')}
        </AccordionDetails>
      </Accordion>

    );
  }

  renderClientCardHeader(client, isOwnerList) {
    const { classes } = this.props;

    const cardControlBox = cn(
      classes.clientCardHeaderItem,
      classes.cardControl
    );

    const cardHeaderClasses = cn({
      [classes.clientCardHeader]: true,
      [classes.ownClientCardHeader]: !isOwnerList && client.isClientMy,
    });

    const controlButtons = isOwnerList ? this.renderOwnerControl(
      {
        createLink: `/clients/${client.id}/filter/create`,
        editLink: `/clients/${client.id}/update`,
        handleDelete: this.handleDelete,
      }
    ) : this.renderAllClientsControl();

    return (
      <Grid container className={ cardHeaderClasses }>
        <Grid item container>
          <Grid item className={classes.clientCardHeaderItem} xs={10} sm={10} md={10}>
            <Typography className={classes.clientFullName}>{client.fullName}</Typography>
          </Grid>
          <Grid item className={cardControlBox} xs={2} sm={2} md={2}>
            {controlButtons}
          </Grid>
          <Grid item className={classes.clientCardHeaderItem} sm={12} md={5}>
            <Typography className={classes.clientTelephoneNumber}>
              <a href={client.telephoneNumber.getCallLink()}>
                {client.telephoneNumber.getTelephoneNumberView()}
              </a>
            </Typography>
            <IconButton className={classes.socialButton} href={client.telephoneNumber.getViberUrl()} target={'_blank'}>
              <img width={22} src={viberLogo}/>
            </IconButton>
            <IconButton
              className={classes.socialButton}
              href={client.telephoneNumber.getTelegramUrl()}
              target={'_blank'}
            >
              <img width={22} src={tgLogo}/>
            </IconButton>
          </Grid>
        </Grid>
        <Grid>
          {this.renderTags(client.tags)}
        </Grid>
      </Grid>
    );
  }

  renderClientParams() {

  }

  render() {
    const { client, classes, isOwnerList } = this.props;

    return (
      <Card className={ classes.clientCard }>
        { isOwnerList ? this.renderOwnClientsHeader(client) : this.renderClientCardHeader(client, isOwnerList) }
        <Grid container md={12} className={classes.filterDataContainer}>
          {this.renderFilters(client.filters, client.id)}
        </Grid>
      </Card>
    );
  }
}

export default withStyles(styles)(ClientCard);

ClientCard.propTypes = {
  classes: PropTypes.object.isRequired,
  client: PropTypes.object,
  onDelete: PropTypes.func,
  onDeleteFilter: PropTypes.func,
  onAddToFavorite: PropTypes.func,
  onRemoveFromFavorite: PropTypes.func,
  paramsList: PropTypes.object,
  onTagClick: PropTypes.func,
  isOwnerList: PropTypes.bool.isRequired,
};
