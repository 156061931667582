export default (theme) => {
  return {
    paramsContainer: {
      '@media (max-width: 900px)': {
        flexDirection: 'column',
      },
    },
    contentBox: {
      flexDirection: 'row',
    },
    contentBoxItem: {
      padding: theme.spacing(2),
    },
    header: {
    },
    headerRow: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: '16px',
      '& cite': {
        color: '#767676',
        fontSize: '1rem',
        fontStyle: 'normal',
        marginRight: '5px',
      },
    },
    headerTitle: {
      color: '#767676',
      fontSize: '1.6rem',
      fontWeight: 700,
      marginBottom: '16px',
    },
    objectPrice: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: '12px',
    },
    objectPriceData: {
      color: '#333333',
      '& strong': {
        fontSize: '2rem',
      },
      '& p': {
        margin: 0,
      },
    },
    location: {
      display: 'flex',
      alignItems: 'center',
      margin: '16px 0',
    },
    locationIcon: {
      color: '#C4C4C4',
    },
    rowLabel: {
      color: '#C1C1C1',
      fontSize: '14px',
      fontWeight: 700,
      marginBottom: '0',
    },
    description: {
      fontSize: '16px',
      lineHeight: '24px',
      color: '#000000',
    },
    notes: {
      fontSize: '16px',
      lineHeight: '24px',
      color: '#000000',
      marginBottom: '30px',
    },
    previewMain: {
      width: '100%',
      '@media (max-width: 900px)': {
        padding: '0',
      },
    },
    previewMainImage: {
      width: '100%',
      cursor: 'zoom-in;',
    },
    subPreview: {
      marginTop: '1%',
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      '@media (max-width: 900px)': {
        padding: '0',
      },
    },
    subPreviewImage: {
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      width: '18%',
      height: '110px',
      cursor: 'zoom-in;',
      '@media (max-width: 3000px)': {
        height: '170px',
      },
      '@media (max-width: 2500px)': {
        height: '150px',
      },
      '@media (max-width: 2000px)': {
        height: '90px',
      },
      '@media (max-width: 1700px)': {
        height: '80px',
      },
      '@media (max-width: 1400px)': {
        height: '70px',
      },
      '@media (max-width: 1200px)': {
        height: '50px',
      },
      '@media (max-width: 600px)': {
        height: '70px',
      },
      '@media (max-width: 450px)': {
        height: '55px',
      },
      '@media (max-width: 390px)': {
        height: '40px',
      },
    },
    subPreviewRest: {
      width: '100%',
      height: '100%',
      background: 'rgba(0, 0, 0, .5)',
      color: '#ffffff',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: '2.5em',
    },
    sliderBox: {
      width: '100%',
    },
    iframe: {
      marginTop: '2%',
      paddingTop: theme.spacing(2),
      '& iframe': {
        border: 'none',
      },
    },
    defaultSliderBox: {
      width: '100%',
      display: 'flex',
      textAlign: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      marginTop: '60px',
      paddingRight: '5%',
      cursor: 'pointer',
    },
    defaultSliderImage: {
      maxWidth: '100%',
    },
    tags: {
      margin: '10px 0',
      fontSize: '16px',
    },
  };
};
