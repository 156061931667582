import React, { Fragment, PureComponent } from 'react';
import { Paper } from '@material-ui/core';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import styles from './styles';

import RealtorPageHeader from '../../layears/RealtorPageHeader/RealtorPageHeader';
import i18n from '../../../../../common/i18n';
import { CircularProgressBox } from '../../layears/CircularProgressBox/CircularProgressBox';
import CreateClientWithFiltersForm from '../../forms/CreateClientWithFiltersForm/CreateClientWithFiltersForm';
import { setPageTitle } from '../../../../../common/helpers/pageTitle';

// TODO: Rewrite to functional component
export class UpdateClientPage extends PureComponent {
  constructor(props) {
    super(props);

    setPageTitle(i18n.t('UPDATE_CLIENT'));
    props.fetchClient(props.clientId);
  }

  static getDerivedStateFromProps(props, state) {
    if (props.clientId !== state.clientId) {
      props.fetchClient(props.clientId);
      return {
        ...state,
        clientId: props.clientId,
      };
    }

    return state;
  }

  state = {
    isFormDisabled: true,
    clientId: null,
  }

  renderHeader() {
    const { classes } = this.props;

    return (
      <RealtorPageHeader>
        <div className={classes.headerBox}>
          <h1>{i18n.t('UPDATE_CLIENT')}</h1>
        </div>
      </RealtorPageHeader>
    );
  }

  handleClientSave = (clientData) => {
    const { clientId, updateClient } = this.props;

    return updateClient(clientId, clientData);
  }

  handleFormWasUpdated = () => {
    const { isFormDisabled } = this.state;

    if (isFormDisabled) {
      this.setState({
        ...this.state,
        isFormDisabled: false,
      });
    }
  }

  renderForm = () => {
    const {
      classes,
      client,
      appConfigurations,
      fetchTagsList,
      tag,
      createNewTag,
      dropCreatedTag,
      dropErrors,
      error,
      inProgress,
      restoreClient,
    } = this.props;

    const { filters, ...clientData } = client.toFormEntity();
    const [filter] = filters;

    const fatsData = {
      ...tag,
      tagsList: tag.tagsList.map((tag) => tag.toFormEntity()),
    };

    return (
      <Fragment>
        <Paper className={classes.paper} elevation={0}>
          <div>
            <CreateClientWithFiltersForm
              dropCreatedTag={dropCreatedTag}
              onConfirm={this.handleClientSave}
              client={clientData}
              filter={ filter || {} }
              appConfigurations={ appConfigurations }
              confirmButtonText={i18n.t('SAVE')}
              disableTypeSelect={filter && Boolean(filter.objectType)}
              onLoadMoreTags={fetchTagsList}
              onAddNewTag={createNewTag}
              tag={fatsData}
              errors={error}
              dropErrors={dropErrors}
              isSaveButtonDisabled={inProgress}
              onConfirmRestore={restoreClient}
            />
          </div>
        </Paper>
      </Fragment>
    );
  }

  renderLoader = () => {
    return <CircularProgressBox />;
  }

  renderContent = () => {
    const { client } = this.props;

    return client ? this.renderForm() : this.renderLoader();
  }

  render() {
    const { classes } = this.props;

    return (
      <Fragment>
        {this.renderHeader()}
        <Paper className={classes.paper} elevation={0}>
          <h2>{i18n.t('INFORMATION_ABOUT_CLIENT')}</h2>
          { this.renderContent() }
        </Paper>
      </Fragment>
    );
  }
}

UpdateClientPage.propTypes = {
  classes: PropTypes.object,
  inProgress: PropTypes.bool.isRequired,
  fetchClient: PropTypes.func.isRequired,
  tag: PropTypes.object.isRequired,
  fetchTagsList: PropTypes.func.isRequired,
  dropCreatedTag: PropTypes.func.isRequired,
  updateClient: PropTypes.func.isRequired,
  createNewTag: PropTypes.func.isRequired,
  clientId: PropTypes.number.isRequired,
  client: PropTypes.object,
  appConfigurations: PropTypes.object,
  dropErrors: PropTypes.func.isRequired,
  error: PropTypes.object,
  restoreClient: PropTypes.func.isRequired,
};

export default withStyles(styles)(UpdateClientPage);
