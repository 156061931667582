import React, { useState, useEffect } from 'react';
import {
  Box,
  TextField,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { withStyles } from '@material-ui/core/styles';
import style from './style';
import PropTypes from 'prop-types';
import clientResource from 'realtor/resourses/Client.resource';
import { Client, TelephoneNumberBuilder } from 'realtor/domain';
import { escapeTelephoneNumberSpecSymbols, isPhoneNumber } from '../../../../helpers/TelephoneNumberHelper';
import AddIcon from '@material-ui/icons/Add';
import i18n from '../../../../i18n';

const GET_CLIENTS_PARAMS = {
  order: 'created_at',
  dir: 'DESC',
};

// eslint-disable-next-line max-statements
const ClientsAssign = ({ classes, onAddClient, onClientSelected, client }) => {
  const [inputValue, setInputValue] = useState('');
  const [clients, setClients] = useState([]);

  useEffect(() => {
    const getData = async() => {
      await getClients();
    };

    getData();
  }, []);

  const mapDataToClientEntity = (item) => new Client({
    ...item,
    moreTelephoneNumbers: item.moreTelephoneNumbers.map(({ telephoneNumber, id }) => {
      return TelephoneNumberBuilder.buildFromServerData(telephoneNumber, id);
    }),
    telephoneNumber: TelephoneNumberBuilder.buildFromServerData(item.telephoneNumber),
  }
  );

  const getClients = async() => {
    const { list } = await clientResource.getAllForSearch(GET_CLIENTS_PARAMS);

    const mappedResult = list.map(mapDataToClientEntity);

    setClients(mappedResult);
  };

  // eslint-disable-next-line max-statements
  const getClientSearchedPhoneNumber = (client) => {
    const { telephoneNumber, moreTelephoneNumbers } = client;

    const primaryPhone = telephoneNumber.getTelephoneNumberView();
    const phones = moreTelephoneNumbers.map((phone) => (
      { id: phone.id, value: `+${phone.getTelephoneNumberFromContract()}` }
    ));
    const regex = /^\+?[0-9]+$/;
    const isPhone = regex.test(inputValue);

    if (inputValue.length > 4 && isPhone) {
      const phoneMatches = phones.reduce((count, { value }) => count + (value.includes(inputValue) ? 1 : 0), 0);

      if (phoneMatches > 1) {
        return primaryPhone;
      }

      const matchedPhone = phones.find(({ value }) => value.includes(inputValue));

      if (matchedPhone) {
        const matchedPhoneId = matchedPhone.id;
        const matchedMoreTelephoneNumber = moreTelephoneNumbers.find((item) =>
          item.getFullProps().id === matchedPhoneId);
        return matchedMoreTelephoneNumber.getTelephoneNumberView() || primaryPhone;
      }
    }

    return primaryPhone;
  };

  const filterOptions = (options, { inputValue }) => {
    return options.filter((option) => {
      const fullName = option.fullName.toLowerCase();
      const searchInput = inputValue.toLowerCase();
      const phones = option.moreTelephoneNumbers.map((phone) => phone.getTelephoneNumberFromContract());

      if (isPhoneNumber(searchInput)) {
        return phones.some((number) => number.includes(escapeTelephoneNumberSpecSymbols(searchInput)));
      }

      return fullName.includes(searchInput) || phones.some((number) => number.includes(searchInput));
    });
  };

  const handleChange = (e, newValue) => {
    onClientSelected(newValue);
  };

  const handleInputChange = (e, newValue) => {
    setInputValue(newValue);
  };

  const getOptionLabel = (option) => `${option.fullName} (${getClientSearchedPhoneNumber(option)})`;

  const handleAddClient = () => {
    onAddClient();
  };

  const renderPaper = ({ children }) => {
    return (
      <div className={classes.styledPaper}>
        <div onMouseDown={handleAddClient} className={classes.addClientButton}>
          <span>{i18n.t('ADD_NEW_SALES')}</span>
          <AddIcon/>
        </div>
        <div>{children}</div>
      </div>
    );
  };

  const label = <label>{i18n.t('CLIENTS')}</label>;

  return (
    <Box>
      {label}
      <Autocomplete
        className={classes.dataAutocomplete}
        value={client}
        onChange={handleChange}
        options={clients}
        fullWidth
        getOptionLabel={getOptionLabel}
        getOptionSelected={(option, value) => option.id === value.id}
        onInputChange={handleInputChange}
        filterOptions={filterOptions}
        PaperComponent={renderPaper}
        renderInput={(params) => (
          <TextField
            variant="outlined"
            placeholder={i18n.t('CLIENT_SEARCH_PLACEHOLDER_SHORT')}
            {...params}
          />
        )}
      />
    </Box>
  );
};

export default withStyles(style)(ClientsAssign);

ClientsAssign.propTypes = {
  classes: PropTypes.object,
  onAddClient: PropTypes.func,
  onClientSelected: PropTypes.func,
  client: PropTypes.object,
};
