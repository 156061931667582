import React, { PureComponent } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import styles from '../styles';
import CommercialParams from './CommercialParams';
import ApartmentParams from './ApartmentParams';
import LandPlotParams from './LandPlotParams';
import GarageParkingParams from './GarageParkingParams';
import HouseParams from './HouseParams';

export class RealEstateParamsFactory extends PureComponent {

  render() {
    const {
      filtersList,
      realEstateObject,
    } = this.props;

    switch (realEstateObject.objectType) {
    case filtersList.objectType.COMMERCIAL:
      return (<CommercialParams
        { ...this.props }
      />);
    case filtersList.objectType.APARTMENT:
      return (<ApartmentParams
        { ...this.props }
      />);
    case filtersList.objectType.LAND_PLOT:
      return (<LandPlotParams
        { ...this.props }
      />);
    case filtersList.objectType.GARAGE_PARKING:
      return (<GarageParkingParams
        { ...this.props }
      />);
    case filtersList.objectType.HOUSE_OR_COUNTRY_HOUSE:
      return (<HouseParams
        { ...this.props }
      />);
    default:
      return (<div></div>);
    }
  }
}

export default withStyles(styles)(RealEstateParamsFactory);

RealEstateParamsFactory.propTypes = {
  realEstateObject: PropTypes.object,
  errors: PropTypes.object,
  filtersList: PropTypes.object,
  onSelect: PropTypes.func.isRequired,
  onMultiSelect: PropTypes.func.isRequired,
  citiesList: PropTypes.array.isRequired,
  onCityChange: PropTypes.func.isRequired,
  onDistrictChange: PropTypes.func.isRequired,
  onInputChange: PropTypes.func.isRequired,
  disableServiceType: PropTypes.bool,
};
