import { connect } from 'react-redux';
import ObjectsListPage from '../components/CabinetPages/ObjectsListPage/ObjectsListPage';
import { fetchMyObjects, clearObjectsList, fetchAnyObjects } from '../actions/objects';
import { deleteObject } from '../actions/object';

const mapStateToProps = ({ myObjects, appConfigurations }) => {
  return {
    myObjects,
    filters: appConfigurations.objectFilters,
    regions: appConfigurations.citiesList,
  };
};

const mapDispatchToProps = {
  fetchMyObjects,
  fetchAnyObjects,
  clearObjectsList,
  deleteObject,
};

export default connect(mapStateToProps, mapDispatchToProps)(ObjectsListPage);
