import React, { PureComponent } from 'react';
import { Button, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import Alert from 'react-s-alert';

import styles from './styles';
import { FormValidation } from '../../../../../common/helpers/FormValidation';
import i18n from '../../../../../common/i18n';
import CreateFilterForm from '../CreateFilterForm/CreateFilterForm';
import { DEFAULT_FILTER } from '../../pages/CreateClientPage/CreateClientPage';
import { clientFilterFormValidationFactory } from 'realtor/validation/clientFilter/clientFilterFormValidationFactory';
import { Link as RouterLink } from 'react-router-dom';
import { getDefaultFilterByType } from 'realtor/helpers/clientFilterParams';

export class CreateSingleFilterForm extends PureComponent {
  constructor(props) {
    super(props);

    this.state.filter = props.filter;
  }

  state = {
    filter: {},
    filterErrors: {},
  }

  validateFilterForm = () => {
    const { appConfigurations } = this.props;

    const validator = clientFilterFormValidationFactory(
      appConfigurations.objectFilters.objectType,
      appConfigurations.exchangeRates,
      this.state.filter
    );

    const errors = validator.validate();

    return {
      isFilterFormValid: FormValidation.isFormValid(errors),
      filterErrors: errors,
    };
  }

  handleSubmit = (e) => {
    const { onConfirm } = this.props;

    e.preventDefault();
    this.setState({
      ...this.state,
      errors: {},
    });

    const { isFilterFormValid, filterErrors } = this.validateFilterForm();

    this.setState({
      ...this.state,
      filterErrors,
    });

    if (isFilterFormValid) {
      onConfirm(this.state.filter);
    } else {
      Alert.error(
        i18n.t('CREATE_CLIENT_FORM_ERROR'),
        { position: 'top-right', effect: 'slide', timeout: 3000 }
      );
    }

    return false;
  }

  handleFilterMultiSelect = (name, value, isAllSelected) => {
    let param = this.state.filter[name];

    if (!param || isAllSelected) {
      param = new Set();
    }

    param.has(value) ? param.delete(value) : param.add(value);

    return this.setState({
      ...this.state,
      filter: {
        ...this.state.filter,
        [name]: param,
      },
    });
  }

  handleFilterMultiSelectAll = (name, params) => {
    return this.setState({
      ...this.state,
      filter: {
        ...this.state.filter,
        [name]: params,
      },
    });
  }

  handleFilterRealEstateSelect = (name, value) => {
    return this.setState({
      ...this.state,
      filter: {
        ...this.state.filter,
        [name]: value,
      },
    });
  }

  handleServiceRealEstateSelect = (name, value) => {
    return this.setState({
      ...this.state,
      filter: {
        ...this.state.filter,
        isMortgage: null,
        [name]: value,
      },
    });
  }

  handleFilterRealEstateTypeSelect = (name, value) => {
    return this.setState({
      ...this.state,
      filterErrors: {},
      filter: {
        ...getDefaultFilterByType(value, this.props.appConfigurations.objectFilters),
        ...DEFAULT_FILTER,
        [name]: value,
      },
    });
  }



  handleCityChange = (event) => {
    this.setState({
      ...this.state,
      filter: {
        ...this.state.filter,
        cityId: get(event, 'cityId', null),
      },
    });
  }

  handleDistrictChange = (data) => {
    this.setState({
      ...this.state,
      filter: {
        ...this.state.filter,
        districtId: data ? data.id : null,
      },
    });
  }

  handleSaveWithoutFilter() {
    Alert.success(
      i18n.t('CLIENT_SUCCESSFULLY_CREATED'),
      { position: 'top-right', effect: 'slide', timeout: 3000 }
    );
  }

  render() {
    const { filter, filterErrors } = this.state;
    const { classes, appConfigurations, confirmButtonText, cancelButtonText, disableTypeSelect } = this.props;

    const buttonTextConfirm = confirmButtonText || i18n.t('ADD_CLIENT');
    const buttonTextCancel = cancelButtonText || i18n.t('CANCEL');
    const isSaveButtonDisabled = !filter.objectType;

    return (
      <form
        onSubmit={this.handleSubmit}
      >
        <div>
          <CreateFilterForm
            filtersList={appConfigurations.objectFilters}
            filter={filter}
            citiesList={appConfigurations.citiesList}
            onMultiSelect={this.handleFilterMultiSelect}
            onFilterRealEstateTypeSelect={this.handleFilterRealEstateTypeSelect}
            onFilterRealEstateSelect={this.handleFilterRealEstateSelect}
            onFilterServiceSelect={this.handleServiceRealEstateSelect}
            onCityChange={this.handleCityChange}
            onDistrictChange={this.handleDistrictChange}
            onMultiSelectAll={this.handleFilterMultiSelectAll}
            errors={filterErrors}
            disableTypeSelect={disableTypeSelect}
          />
        </div>
        <Grid container spacing={2} className={classes.controlButtons}>
          <Grid item xs={6}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={isSaveButtonDisabled}
            >
              {buttonTextConfirm}
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              fullWidth
              variant="contained"
              color="default"
              className={classes.cancel}
              component={RouterLink} to={'/clients'}
              onClick={this.handleSaveWithoutFilter}
            >
              {buttonTextCancel}
            </Button>
          </Grid>
        </Grid>
      </form>
    );
  }
}

export default withStyles(styles)(CreateSingleFilterForm);

CreateSingleFilterForm.propTypes = {
  filter: PropTypes.object,
  classes: PropTypes.object,
  onConfirm: PropTypes.func,
  appConfigurations: PropTypes.object,
  confirmButtonText: PropTypes.string,
  cancelButtonText: PropTypes.string,
  disableTypeSelect: PropTypes.bool,
};
