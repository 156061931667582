import React, { Fragment, PureComponent } from 'react';
import {
  TextField,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { withStyles } from '@material-ui/core/styles';
import style from './style';
import PropTypes from 'prop-types';
import { set, get } from 'lodash';
import { CityService } from '../../../../services/CityService';
import i18n from '../../../../../common/i18n';

class CityAutocomplete extends PureComponent {

  state = {
    value: null,
    inputValue: '',
  }

  static getDerivedStateFromProps({ regions, cityId }, prevState) {
    if (!cityId) {
      const newState = {
        value: null,
      };
      if (!prevState.inputValue) {
        newState.inputValue = '';
      }
      return newState;
    }
    const city = CityService.getCityFormRegions(regions, cityId);
    if (regions && regions.length && city.name !== get(prevState, 'value.city')) {
      const region = CityService.getRegion(regions, city.regionId);
      const value = {
        region: region.name,
        city: city.name,
      };
      return {
        value,
      };
    }

    return prevState;
  }

  handleInputValueChange(inputValue) {
    this.setState({
      ...this.state,
      inputValue,
    });
  }

  getOptions() {
    const { regions } = this.props;
    let options = [];
    regions.forEach((region) => {
      const citiesList = region.cities.map((city) => {
        return {
          region: region.name,
          city: city.name,
          regionId: region.id,
          cityId: city.id,
          withDistricts: Boolean(get(city, 'districts.length', false)),
        };
      });
      options = [...options, ...citiesList];
    });
    return options;
  }

  render() {
    const {
      value,
      inputValue,
    } = this.state;

    const { onCityChange, error, classes, cityHeader } = this.props;
    const label = cityHeader ?
      <h3>{cityHeader}</h3> :
      <label id={'city'}>{ i18n.t('CITY') }</label>;

    return (
      <Fragment>
        {label}
        <Autocomplete
          className={classes.cityAutocomplete}
          value={value}
          onChange={(event, newValue) => {
            onCityChange(newValue);
          }}
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => {
            this.handleInputValueChange(newInputValue);
          }}
          id="controllable-states-demo"
          options={this.getOptions()}
          fullWidth
          groupBy={(option) => option.region}
          getOptionLabel={(option) => option.city}
          renderInput={
            (params) => {
              set(params, 'inputProps.autoComplete', 'chrome-off');

              return (
                <TextField
                  variant="outlined"
                  {...params}
                  helperText={error}
                  error={!!error}
                />
              );
            }
          }
        />
      </Fragment>
    );
  }
}

export default withStyles(style)(CityAutocomplete);

CityAutocomplete.propTypes = {
  regions: PropTypes.array,
  city: PropTypes.string,
  onCityChange: PropTypes.func,
  error: PropTypes.string,
  cityHeader: PropTypes.string,
  cityId: PropTypes.number,
  classes: PropTypes.object,
  errors: PropTypes.string,
};
