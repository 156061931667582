import { connect } from 'react-redux';
import ObjectPage from '../components/CabinetPages/ObjectPage/ObjectPage';
import { getMyRealEstateObject } from 'realtor/actions';

const mapStateToProps = ({ appConfigurations, realEstateObject }, { match }) => {
  return {
    appConfigurations,
    inProgress: realEstateObject.inProgress,
    realEstateObject: realEstateObject.realEstateObject,
    realEstateObjectId: Number(match.params.realEstateObjectId),
    filters: appConfigurations.objectFilters,
  };
};

const mapDispatchToProps = {
  fetchObject: getMyRealEstateObject,
};

export default connect(mapStateToProps, mapDispatchToProps)(ObjectPage);
