import React, { Fragment, PureComponent } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Button, Grid } from '@material-ui/core';
import { LocationOn, EditOutlined, DeleteOutline, FavoriteBorder, Favorite } from '@material-ui/icons';
import PropTypes from 'prop-types';

import styles from './styles';
import i18n from '../../../../../common/i18n';
import { formatPriceNumber } from 'realtor/helpers/formatPriceNubmer';
import {
  getRealEstateRoomsCountTranslation,
  getRealEstateServiceTypeTranslation,
  getRealEstateSubtypeTranslation,
} from 'realtor/helpers/objectParams';
import { Link, useHistory } from 'react-router-dom';
import ClientContacts from 'realtor/components/layears/ObjectCard/components/ClientContacts/ClientContacts';
import TagsList from 'realtor/components/layears/ObjectCard/components/TagsList/TagsList';
import ChipByType from 'realtor/components/layears/ChipByType/ChipByType.';
import CustomSlider from 'realtor/components/common/CustomSlider/CustomSlider';
import TextTruncate from 'realtor/components/layears/TextTruncate/TextTruncate';

function EditButton({ id, classes }) {
  const history = useHistory();

  const handleUpdate = () => {
    history.push(`/real-estate-object/${id}/edit`);
  };
  return <Button className={classes.controlsButton} onClick={handleUpdate}><EditOutlined/></Button>;
}

class ObjectCard extends PureComponent {

  handleDelete = (e) => {
    e.stopPropagation();

    const { onDelete, object } = this.props;
    return onDelete(object);
  }

  renderObjectParams = () => {
    const { object, classes, paramsList } = this.props;
    const { subtype, serviceType, type, area, roomsCount, city, floor, flooring, district } = object;
    const realEstateSubtype = getRealEstateSubtypeTranslation(paramsList, type, subtype);
    const realEstateServiceType = getRealEstateServiceTypeTranslation(paramsList, serviceType);
    const realEstateRoomsCount = getRealEstateRoomsCountTranslation(paramsList, roomsCount);
    const districtSlug = district ? `, ${district.name} ${i18n.t('DISTRICT').toLowerCase()}` : '';
    return (
      <div className={classes.objectParams}>
        <LocationOn className={classes.locationIcon}/>
        <span>{city.name}{districtSlug}</span>
        <span className={classes.roundDivider}></span>
        <span>{realEstateServiceType}</span>
        {realEstateSubtype &&
          <Fragment>
            <span className={classes.roundDivider}></span>
            <span>{realEstateSubtype}</span>
          </Fragment>
        }
        {roomsCount &&
          <Fragment>
            <span className={classes.roundDivider}></span>
            <span>{realEstateRoomsCount} ком.</span>
          </Fragment>}
        {area &&
          <Fragment>
            <span className={classes.roundDivider}></span>
            <span>{area} кв.м.</span>
          </Fragment>}
        {floor && flooring &&
          <Fragment>
            <span className={classes.roundDivider}></span>
            <span>{floor}/{flooring}</span>
          </Fragment>}
      </div>);
  }

  renderDescription = () => {
    const { object, classes } = this.props;
    const { description = '' } = object;

    return (
      <div className={classes.objectDescription}>
        <TextTruncate text={description || ''} />
      </div>);
  }

  renderControls = () => {
    const { classes, isOwnerList, isFavourite = false, object } = this.props;
    const { id } = object;
    const editButton = <EditButton classes={classes} id={id} />;
    const deleteButton = (
      <Button className={classes.controlsButton} onClick={this.handleDelete}>
        <DeleteOutline/>
      </Button>
    );
    const favouriteButton = (
      <Button className={classes.controlsButton}>
        {isFavourite ? <Favorite/> : <FavoriteBorder/>}
      </Button>
    );

    return (
      <div className={classes.controls}>
        {isOwnerList ?
          <Fragment>
            {editButton}
            {deleteButton}
          </Fragment> : favouriteButton
        }
      </div>);
  }

  renderContacts = () => {
    const { object } = this.props;
    return <ClientContacts client={object.client} />;
  };

  renderTags = () => {
    const { object, onTagClick } = this.props;
    return <TagsList tags={object.tags} onTagClick={onTagClick} />;
  };

  render() {
    const { object, classes, paramsList } = this.props;
    const { id, totalPriceUah, totalPriceUsd, title, virtualTourUrl } = object;

    const priceByCurrency = formatPriceNumber(totalPriceUah || totalPriceUsd || 500000);
    const currencyLabel = totalPriceUsd ? i18n.t('CURRENCY_USD_LITERAL') : i18n.t('CURRENCY_UAH_LITERAL');

    return (
      <Grid item xs={12} md={6} lg={4} xl={3}>
        <div className={classes.objectCard}>
          <div className={classes.sliderWrap}>
            <ChipByType
              paramsList={paramsList}
              object={object}
            />
            {virtualTourUrl && <div className={classes.virtualTourChip}>{i18n.t('3D_TOUR_LANG')}</div>}
            <CustomSlider key={id} images={object.photos} objectId={object.id}/>
            {this.renderControls()}
          </div>
          <Link target="_blank" className={classes.objectTitle} to={`/real-estate-object/${object.id}`}>
            <span>{title}</span>
          </Link>
          <Link target="_blank" to={`/real-estate-object/${object.id}`}>
            {this.renderObjectParams()}
            {this.renderDescription()}
          </Link>
          <div className={classes.objectPrice}>
            <strong>{priceByCurrency} {currencyLabel}</strong>
          </div>
          {this.renderTags()}
          {this.renderContacts()}
        </div>
      </Grid>
    );
  }
}

export default withStyles(styles)(ObjectCard);

EditButton.propTypes = {
  id: PropTypes.number,
  classes: PropTypes.object.isRequired,
};

ObjectCard.propTypes = {
  classes: PropTypes.object.isRequired,
  object: PropTypes.object,
  isOwnerList: PropTypes.bool,
  isFavourite: PropTypes.bool,
  paramsList: PropTypes.object,
  onTagClick: PropTypes.func,
  onDelete: PropTypes.func,
};
