import axios from '../../../common/axios';

class ObjectResource {
  /**
   * Get real estate objects list
   */
  getMyObjectsList(params) {
    return axios.get('/v1/realtor/my/real-estate', { params })
      .then((res) => res.data);
  }

  /**
   * Get total real estate objects count
   */
  // getMyObjectsTotal() {
  //   return axios.get('/v1/realtor/my/real-estate/total', {})
  //     .then((res) => res.data);
  // }

  getObjectsList(params) {
    return axios.get('/v1/realtor/my/real-estate', { params })
      .then((res) => res.data);
  }

  /**
   * Get total real estate objects count
   */
  // getObjectsTotal() {
  //   return axios.get('/v1/realtor/real-estate/total', {})
  //     .then((res) => res.data);
  // }

  /**
   * Delete object
   */
  deleteObject(id) {
    return axios.delete(`/v1/realtor/my/real-estate/${id}`)
      .then((res) => res.data);
  }
}

export default new ObjectResource();
