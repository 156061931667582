import axios from '../../../common/axios';

class RealEstateObjectResource {
  /**
   * Create new real estate object
   */
  create(realEstateObjectData) {
    return axios.post('/v1/realtor/my/real-estate', realEstateObjectData)
      .then((res) => res.data);
  }

  /**
   * Create new real estate object
   */
  update(id, realEstateObjectData) {
    return axios.put(`/v1/realtor/my/real-estate/${id}`, realEstateObjectData)
      .then((res) => res.data);
  }

  /**
   * Create new real estate object
   */
  getMyOne(id) {
    return axios.get(`/v1/realtor/my/real-estate/${id}`)
      .then((res) => res.data);
  }

  /**
   * Add new object photo
   * @param id
   * @param data
   * @returns {Promise<AxiosResponse<any>>}
   */
  addObjectPhotos(id, data) {
    return axios.post(
      `/v1/realtor/my/real-estate/${id}/photos`,
      data,
      { headers: { 'Content-Type': 'multipart/form-data' } }
    )
      .then((res) => res.data);
  }

  /**
   * Delete real estate single photo
   * @param realEstateObjectId
   * @param imageId
   * @returns {Promise<AxiosResponse<any>>}
   */
  deleteObjectImage(realEstateObjectId, imageId) {
    return axios.delete(`/v1/realtor/my/real-estate/${realEstateObjectId}/photo/${imageId}`)
      .then((res) => res.data);
  }
}

export default new RealEstateObjectResource();
