import React from 'react';
import CustomSwitch from 'realtor/components/common/CustomSwitch/CustomSwitch';
import i18n from '../../../../../common/i18n';
import PropTypes from 'prop-types';

export const LastFloorFilter = ({ filter, onFilterChange }) => {
  const name = 'isLastFloor';
  return (
    <CustomSwitch
      label={i18n.t('LAST_FLOOR')}
      name={name}
      checked={filter[name]}
      onChange={onFilterChange}
    />
  );
};

LastFloorFilter.propTypes = {
  filter: PropTypes.object.isRequired,
  onFilterChange: PropTypes.func.isRequired,
};
