import React, { PureComponent } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { IconButton, Select, MenuItem } from '@material-ui/core';
import cn from 'classnames';

import styles from './styles';
import PropTypes from 'prop-types';

class Pagination extends PureComponent {
  handleLimitChange = ({ target }) => {
    const { onLimitChange } = this.props;

    onLimitChange(target.value);
  }

  renderCountOfPagination = () => {
    const { classes, onPaginationBack, onPaginationForward, limit, offset, total } = this.props;
    const boxClasses = cn(
      classes.paginationCounterBox,
      classes.paginationContent
    );

    const finishCount = total - offset - limit > 0 ? offset + limit : total;
    return (
      <div className={boxClasses}>
        <span className={classes.showedText}><b>Показано:</b></span>
        <IconButton
          disabled={!offset}
          onClick={onPaginationBack}
          className={classes.paginationNavButton}
        >
          <svg className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-vubbuv" focusable="false" aria-hidden="true"
            viewBox="0 0 24 24" data-testid="KeyboardArrowLeftIcon">
            <path d="M15.41 16.09l-4.58-4.59 4.58-4.59L14 5.5l-6 6 6 6z"></path>
          </svg>
        </IconButton>
        <span>{`${offset + 1}-${finishCount} з ${total}`}</span>
        <IconButton
          disabled={offset + limit >= total}
          onClick={onPaginationForward}
          className={classes.paginationNavButton}
        >
          <svg className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-vubbuv" focusable="false" aria-hidden="true"
            viewBox="0 0 24 24" data-testid="KeyboardArrowRightIcon">
            <path d="M8.59 16.34l4.58-4.59-4.58-4.59L10 5.75l6 6-6 6z"></path>
          </svg>
        </IconButton>
      </div>
    );
  }

  renderSelectLimit = () => {
    const { classes, limitsList, limit } = this.props;

    return (
      <div className={classes.paginationContent}>
        <Select
          variant={'outlined'}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={limit}
          onChange={this.handleLimitChange}
        >
          {limitsList.map(
            (limit, key) => {
              return (<MenuItem key={key} value={limit}>{limit}</MenuItem>);
            })}
        </Select>
      </div>
    );
  }

  render() {
    const { classes, isBottom } = this.props;
    const boxName = cn( { [classes.paginationBottom]: isBottom },classes.paginationBox);
    return (
      <div className={boxName} >
        {this.renderCountOfPagination()}
        {this.renderSelectLimit()}
      </div>
    );
  }
}

export default withStyles(styles)(Pagination);

Pagination.propTypes = {
  classes: PropTypes.object.isRequired,
  isBottom: PropTypes.object,
  onLimitChange: PropTypes.func,
  onPaginationBack: PropTypes.func,
  onPaginationForward: PropTypes.func,
  limitsList: PropTypes.array,
  limit: PropTypes.number,
  offset: PropTypes.number,
  total: PropTypes.number,
};
