import { connect } from 'react-redux';
import { ProfilePage } from 'realtor/components/pages/ProfilePage/ProfilePage';
import { updateClientProfile } from 'realtor/actions';
import { logout } from '../../allAccess/actions/authorization';

const mapStateToProps = ({ user }) => {
  return {
    userProfile: user.profile,
  };
};

const mapDispatchToProps = {
  updateClientProfile,
  logout,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePage);
