import { connect } from 'react-redux';

import CreateClientFilterPage from '../components/pages/CreateClientFilterPage/CreateClientFilterPage';

import { createClientFilter } from '../actions/';

const mapStateToProps = (state, { match }) => {
  const { appConfigurations } = state;

  return {
    clientId: Number(match.params.clientId),
    appConfigurations,
  };
};

const mapDispatchToProps = {
  createClientFilter,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateClientFilterPage);
