import React, { PureComponent } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import ConfirmUserProfileContainer from '../containers/ConfirmUserProfileContainer';

export default class AccountConfirmationRoutes extends PureComponent {
  render() {
    return (
      <Switch>
        <Route path="/profile/confirm" component={ConfirmUserProfileContainer}/>
        <Redirect from="*" to="/profile/confirm" />
      </Switch>
    );
  }
}
