import React, { PureComponent, Fragment } from 'react';
import { get } from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import style from './style';
import PropTypes from 'prop-types';
import CityField from './CityField';
import DistrictField from './DistrictField';
import { CityService } from '../../../../services/CityService';

const DEFAULT_GRID_PARAMS = {
  sm: 12,
  md: 6,
  lg: 6,
  xl: 4,
  xs: 4,
};

class Location extends PureComponent {

  state = {
    districtsList: [],
  };

  static getDerivedStateFromProps({ regions, cityId }, state) {
    if (regions && cityId) {
      const city = CityService.getCityFormRegions(regions, cityId);
      return {
        ...state,
        districtsList: city.districts,
      };
    }
    return state;
  }

  getGridParams = () => {
    return this.props.gridParams || DEFAULT_GRID_PARAMS;
  }

  handleCityChange = (location) => {
    const {
      regions,
      onCityChange,
    } = this.props;
    let districtsList = [];
    if (location) {
      const region = regions.find(({ name }) => name === location.region);
      const city = region.cities.find(({ name }) => name === location.city);
      if (location.withDistricts) {
        districtsList = get(city, 'districts.length', []);
      }
    }
    this.setState({
      ...this.state,
      districtsList,
    });
    return onCityChange(location);
  }

  render() {
    const {
      cityError,
      onDistrictChange,
      selectedDistrict,
      regions,
      cityId,
      districtError,
      classes,
      cityHeader,
      districtHeader,
    } = this.props;
    const {
      districtsList,
    } = this.state;

    const showDistrictsList = cityId && !!districtsList.length;

    return (
      <Fragment>
        <Grid className={classes.locationTextField} item {...this.getGridParams()}>
          {regions && <CityField
            regions={regions}
            cityId={cityId}
            onCityChange={this.handleCityChange}
            error={cityError}
            cityHeader={cityHeader}
          />}
        </Grid>
        {showDistrictsList &&
        <Grid className={classes.locationTextField} item {...this.getGridParams()}>
          <DistrictField
            districtsList={districtsList}
            onDistrictChange={onDistrictChange}
            selectedDistrict={selectedDistrict}
            error={districtError}
            districtHeader={districtHeader}
          />
        </Grid>
        }
      </Fragment>
    );
  }
}

export default withStyles(style)(Location);

Location.propTypes = {
  regions: PropTypes.array,
  cityId: PropTypes.number,
  onCityChange: PropTypes.func,
  onDistrictChange: PropTypes.func,
  selectedDistrict: PropTypes.number,
  cityError: PropTypes.string,
  districtError: PropTypes.string,
  gridParams: PropTypes.object,
  classes: PropTypes.object,
  cityHeader: PropTypes.string,
  districtHeader: PropTypes.string,
  errors: PropTypes.object,
};
