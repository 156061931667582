/* eslint-disable import/max-dependencies */
import {
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import React from 'react';
import PropTypes from 'prop-types';

import ClientListContainer from '../containers/ClientListContainer';
import CreateClientContainer from '../containers/CreateClientContainer';
import ClientUpdateContainer from '../containers/ClientUpdateContainer';
import UpdateClientFilterContainer from '../containers/UpdateClientFilterContainer';
import CreateClientFilterContainer from '../containers/CreateClientFilterContainer';
import ProfilePageContainer from '../containers/ProfilePageContainer';
import SearchClientPageContainer from '../containers/SearchClientPageContainer';
import CreateRealEstateObjectPageContainer from '../containers/CreateRealEstateObjectPageContainer';
import UpdateRealEstateObjectPageContainer from '../containers/UpdateRealEstateObjectPageContainer';
import NotFoundPage from 'realtor/components/pages/NotFoundPage/NotFoundPage';
import MyObjectsPageContainer from '../containers/MyObjectsPageContainer';
import SearchObjectPageContainer from 'realtor/containers/SearchObjectPageContainer';
import AssignClientContainer from '../containers/AssignClientContainer';
import ObjectPage from '../../containers/ObjectPageContainer';
import ShareObjectPage from '../../allAccess/containers/ShareObjectPage';
import ShareObjectsListPage from '../../containers/ShareObjectsListPage';

export const RealtorRoutes = (props) => {
  const { redirectPath } = props;

  return (
    <Switch>
      <Route exact path="/">
        <Redirect to="/clients" />
      </Route>
      {redirectPath && <Redirect to={redirectPath} push /> }
      <Route path="/clients" exact component={ClientListContainer} />
      <Route path="/search-clients" exact component={SearchClientPageContainer} />
      <Route path="/objects" exact component={MyObjectsPageContainer} />
      <Route path="/search-objects" exact component={SearchObjectPageContainer} />
      <Route path="/clients/create" exact component={CreateClientContainer} />
      <Route path="/profile" exact component={ProfilePageContainer} />
      <Route path="/clients/:clientId/update" exact component={ClientUpdateContainer} />
      <Route path="/clients/:clientId/filter/:filterId/update" exact component={UpdateClientFilterContainer} />
      <Route path="/clients/:clientId/filter/create" exact component={CreateClientFilterContainer} />
      <Route path="/real-estate-object/create" exact component={CreateRealEstateObjectPageContainer} />
      <Route path="/assign-client" exact component={AssignClientContainer} />
      <Route
        path="/real-estate-object/:realEstateObjectId"
        exact
        component={ObjectPage}
      />
      <Route
        path="/real-estate-object/:realEstateObjectId/edit"
        exact
        component={UpdateRealEstateObjectPageContainer}
      />
      <Route path="/share/object" component={ShareObjectPage}/>
      <Route path="/share/objects" component={ShareObjectsListPage}/>
      <Route path="/404" exact component={NotFoundPage} />
      <Redirect from="/auth" exact to="/clients" />
      <Redirect from="*" to="/404" />
    </Switch>
  );
};

RealtorRoutes.propTypes = {
  redirectPath: PropTypes.string,
};
