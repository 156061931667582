import { BaseValidator } from './BaseValidator';
import { FormValidation } from '../../../../../common/helpers/FormValidation';
import { NOTES_AND_DESCRIPTION_LENGTH } from '../../../../constants/validation';

export class LandPlotFilterValidator extends BaseValidator{
  // eslint-disable-next-line max-statements
  validate() {
    const errors = {};

    errors.area = this.validateArea();

    errors.landAreaPrice = this.validateLandAreaPrice();

    errors.totalPrice = this.validateTotalPrice();

    errors.objectPartialServiceType = FormValidation.validateRequired(this.filter.objectPartialServiceType);

    errors.description = FormValidation.validateLength(this.filter.description, 0, NOTES_AND_DESCRIPTION_LENGTH);

    if (!errors.area) {
      errors.areaFrom = this.validateMaxValue(this.filter.areaFrom);
      errors.areaTo = this.validateMaxValue(this.filter.areaTo);
    }

    if (!errors.landAreaPrice) {
      errors.landAreaPriceFrom = this.validatePriceWithCurrency(this.filter.landAreaPriceFrom);
      errors.landAreaPriceTo = this.validatePriceWithCurrency(this.filter.landAreaPriceTo);
    }

    if (!errors.totalPrice) {
      const requiredError = FormValidation.validateRequired(this.filter.totalPriceTo);

      errors.totalPriceFrom = this.validatePriceWithCurrency(this.filter.totalPriceFrom);
      errors.totalPriceTo = requiredError ? requiredError : this.validatePriceWithCurrency(this.filter.totalPriceTo);
    }

    return errors;
  }
}
