import {
  buildRealEstateObjectFromServer,
  realEstateObjectToDomainFactory,
} from 'realtor/domain/factories/realEstateObjectToDomainFactory';
import RealEstateObjectResource from 'realtor/resourses/RealEstateObject.resource';
import { CreateRealEstateRequestDataBuilder } from 'realtor/helpers/requestDataBuildes';
import Alert from 'react-s-alert';
import i18n from '../../../common/i18n';
import { onRedirect } from '../../actions/redirect';
import { TelephoneNumberBuilder, Client } from 'realtor/domain';
import { FormDataHelper } from '../../helpers/FormDataHelper';
import { get } from 'lodash';
import {
  UpdateRealEstateRequestDataBuilder,
} from 'realtor/helpers/requestDataBuildes/UpdateRealEstateRequestDataBuilder';

export const DELETE_OBJECT_IMAGE_START = 'DELETE_OBJECT_IMAGE_START';
export const DELETE_OBJECT_IMAGE_SUCCESS = 'DELETE_OBJECT_IMAGE_SUCCESS';
export const DELETE_OBJECT_IMAGE_FAIL = 'DELETE_OBJECT_IMAGE_FAIL';
export const CREATE_REAL_ESTATE_OBJECT_START = 'CREATE_REAL_ESTATE_OBJECT_START';
export const CREATE_REAL_ESTATE_OBJECT_FAIL = 'CREATE_REAL_ESTATE_OBJECT_FAIL';
export const CREATE_REAL_ESTATE_OBJECT_SUCCESS = 'CREATE_REAL_ESTATE_OBJECT_SUCCESS';
export const FETCH_MY_REAL_ESTATE_OBJECT_START = 'FETCH_MY_REAL_ESTATE_OBJECT_START';
export const FETCH_MY_REAL_ESTATE_OBJECT_FAIL = 'FETCH_MY_REAL_ESTATE_OBJECT_FAIL';
export const FETCH_MY_REAL_ESTATE_OBJECT_SUCCESS = 'FETCH_MY_REAL_ESTATE_OBJECT_SUCCESS';
export const ADD_OBJECT_IMAGES_START = 'ADD_OBJECT_IMAGES_START';
export const ADD_OBJECT_IMAGES_SUCCESS = 'ADD_OBJECT_IMAGES_SUCCESS';
export const ADD_OBJECT_IMAGES_FAIL = 'ADD_OBJECT_IMAGES_FAIL';

const deleteImageObjectStart = () => {
  return { type: DELETE_OBJECT_IMAGE_START };
};

const deleteImageObjectFail = (error) => {
  return {
    type: DELETE_OBJECT_IMAGE_FAIL,
    error,
  };
};

const deleteImageObjectSuccess = (id) => {
  return {
    type: DELETE_OBJECT_IMAGE_SUCCESS,
    id,
  };
};

const createRealEstateObjectStart = () => {
  return { type: CREATE_REAL_ESTATE_OBJECT_START };
};

const getMyRealEstateObjectStart = () => {
  return { type: FETCH_MY_REAL_ESTATE_OBJECT_START };
};

const getMyRealEstateObjectError = (error) => {
  return { type: FETCH_MY_REAL_ESTATE_OBJECT_FAIL, error };
};

const getMyRealEstateObjectSuccess = (realEstateObject) => {
  return { type: FETCH_MY_REAL_ESTATE_OBJECT_SUCCESS, realEstateObject };
};

const addImageStart = () => {
  return { type: ADD_OBJECT_IMAGES_START };
};

const addImageFail = (error) => {
  return { type: ADD_OBJECT_IMAGES_FAIL, error };
};

const addImageSuccess = (photos) => {
  return { type: ADD_OBJECT_IMAGES_SUCCESS, payload: photos };
};

// TODO: Add possibility to load photos throw single request
export const createRealEstateObject = (realEstateObject) => {
  return (dispatch, getState) => {
    const state = getState();

    const realEstateObjectDomain = realEstateObjectToDomainFactory(
      realEstateObject,
      state.appConfigurations.objectFilters.objectType
    );

    const requestData = CreateRealEstateRequestDataBuilder.build({ realEstateObjectDomain });

    dispatch(createRealEstateObjectStart());

    RealEstateObjectResource.create(requestData)
      .then((response) => {
        const formData = FormDataHelper.buildFormDataForSaveObjectPhotos(realEstateObject.photos);
        RealEstateObjectResource.addObjectPhotos(response.id, formData)
          .finally(() => {
            Alert.success(
              i18n.t('REAL_ESTATE_OBJECT_SUCCESSFULLY_CREATED'),
              { position: 'top-right', effect: 'slide', timeout: 3000 }
            );
            return dispatch(onRedirect(`/real-estate-object/${response.id}`));
          });
      });
  };
};

export const updateRealEstateObject = (realEstateObjectId, realEstateObject) => {
  return (dispatch, getState) => {
    const state = getState();

    const realEstateObjectDomain = realEstateObjectToDomainFactory(
      realEstateObject,
      state.appConfigurations.objectFilters.objectType
    );

    const requestData = UpdateRealEstateRequestDataBuilder.build({ realEstateObjectDomain });

    RealEstateObjectResource.update(realEstateObjectId, requestData)
      .then(() => {
        Alert.success(
          i18n.t('REAL_ESTATE_OBJECT_SUCCESSFULLY_UPDATED'),
          { position: 'top-right', effect: 'slide', timeout: 3000 }
        );

        return dispatch(onRedirect(`/real-estate-object/${realEstateObjectId}`));
      });
  };
};

export const getMyRealEstateObject = (id) => {
  return (dispatch, getState) => {
    const state = getState();

    dispatch(getMyRealEstateObjectStart());

    RealEstateObjectResource.getMyOne(id)
      .then(({ client, ...realEstateObject }) => {
        let clientDomain = null;
        if (client) {
          clientDomain = new Client({
            ...client,
            telephoneNumber: TelephoneNumberBuilder.buildFromServerData(client.telephoneNumber),
            moreTelephoneNumbers: client.moreTelephoneNumbers.map(
              (moreTelephoneNumber) => TelephoneNumberBuilder.buildFromServerData(moreTelephoneNumber)
            ),
          });
        }

        const realEstateObjectDomain = buildRealEstateObjectFromServer({
          ...realEstateObject,
          client: clientDomain,
        }, state.appConfigurations.objectFilters.objectType);

        dispatch(getMyRealEstateObjectSuccess(realEstateObjectDomain.props));
      })
      .catch(getMyRealEstateObjectError);
  };
};

export const addObjectPhotos = (id, photos) => {
  return (dispatch) => {
    const formData = FormDataHelper.buildFormDataForSaveObjectPhotos(photos);
    dispatch(addImageStart());
    RealEstateObjectResource.addObjectPhotos(id, formData)
      .then((photos) => {
        return dispatch(addImageSuccess(photos));
      })
      .catch((err) => {
        dispatch(addImageFail(get(err, 'response.data.error', null)));
      });
  };
};

export const deleteObjectPhoto = (objectId, imageId) => {
  return (dispatch) => {
    dispatch(deleteImageObjectStart());
    RealEstateObjectResource.deleteObjectImage(objectId, imageId)
      .then((response) => {
        dispatch(deleteImageObjectSuccess(Number(response.id)));
      })
      .catch((err) => {
        dispatch(deleteImageObjectFail(get(err, 'response.data.error', null)));
      });
  };
};

